import { AtlasFlex, MyModal } from 'atlas-ds'
import Button from 'components/reform/Button'

export default function AskSaveModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Modifications non enregistrées"
            okButton={<Button onClick={ props.next }>Continuer</Button>}
            cancelButton={<Button onClick={ props.close }>Retour</Button>}
        >
            <AtlasFlex column gap="l">
                <p>Vous avez fait des modifications qui n'ont pas été enregistrées avec le bouton 'Suivant', êtes-vous sûr de quitter cet écran ?</p>
            </AtlasFlex>
        </MyModal>
    )
}