import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { MyModal } from 'atlas-ds'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Diagnostic, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'


interface DiagDeleteModalProps {
    diagnostic: Diagnostic | null
    close: () => void
}

export default function DiagDeleteModal(props: DiagDeleteModalProps) {
    const dispatch = useAppDispatch()

    const [deletePending, setDeletePending] = useState<boolean>(false)

    const history = useHistory()

    const handleDelete = () => {
        setDeletePending(true)

        dispatch(diagnosticSliceActions.deleteAll(props.diagnostic!))
            .then(unwrapResult)
            .then(() => history.push('/'))
            .finally(() => props.close())
    }

    return (
        <MyModal
            label="Voulez-vous vraiment supprimer votre diagnostic et son plan d'action associé ?"
            onClose={ props.close }
            okButton={ <Button
                destructive
                onClick={ handleDelete }
                spinner={{spinning: deletePending}}
                disabled={ deletePending }
            >Supprimer</Button> }
            cancelButton={ <Button onClick={ props.close } disabled={ deletePending }>Retour</Button> }
        />
    )
}
