import { Dossier, DossierDetail, DossiersSearchQuery, exportDossiers, getDossiers } from 'api/dossierAPI'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE, ETAT_DOSSIER_ACCORDE, ETAT_DOSSIER_ANNULE, ETAT_DOSSIER_BROUILLON, ETAT_DOSSIER_EN_COURS, getAllDispositifFO, getAllEtatDossierFO } from 'api/references'
import { Reference } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasText, MyTableColumn } from 'atlas-ds'
import { formatCurrency } from 'components/format/Format'
import XLinkDossier from 'components/link/XLinkDossier'
import NewRequest from 'components/NewRequest'
import DelaiTraitementPrestataire from 'components/recherche/DelaiTraitementPrestataire'
import { FiltreCheckboxes, FiltrePeriode, FiltreRadioGroup, FiltreRecherche } from 'components/recherche/Filtres'
import RechercheBase, { DEFAULT_DATE_RANGES, FiltreFunctionProps, singleTab } from 'components/recherche/RechercheBase'
import DossierDeposerFactureSelfe from 'pages/detail-dossier/DossierDeposerFactureSelfe'
import DeleteModal from 'pages/dossier/DeleteModal'
import { ColonneDateSource, ColonneDateSourceFF, ColonneDispositif, ColonneEtat, ColonneFormationEntrepriseFF, ColonneIntitule, ColonneMontantAccordeFF, ColonneNumero, ColonneNumeroFF, ColonneStagiaire, ColonneStagiaireFF, DossiersSearchQueryImpl, dateTypeOptions, defaultDateType } from 'pages/mes-dossiers-contrats/MesDossiersContrats'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FiltreOrganisme } from '../FiltresPrestataire'


export default function MesDossiersPrestataire() {

    const [showDeleteModal, setShowDeleteModal] = useState<{ show: boolean, dossier?: Dossier }>({ show: false })

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const dispositifCheckboxes = (getAllDispositifFO() ?? []).map((dispositif: Reference) => ({
        label: dispositif.Libelle__c,
        value: dispositif.Id
    }))

    const etatDossiersCheckboxes = (getAllEtatDossierFO() ?? []).map((etat: Reference) => ({
        label: etat.Libelle__c,
        value: etat.Id,
    }))

    const getFiltres = (props: FiltreFunctionProps) => {
        const filtres = [
            FiltreRecherche({ label: "Recherche", placeholder: "Nom ou prénom stagiaire, intitulé, numéro..." }),
            FiltrePeriode({ label: "Par date de ", placeholder: "Choisir une période", minDate: new Date(2019, 0, 1), defaultDateType: defaultDateType, dateTypeOptions: dateTypeOptions, dateRanges: DEFAULT_DATE_RANGES }),
            FiltreOrganisme({}),
            FiltreCheckboxes({ name: 'dispositifIds', label: "Dispositif", valueName: 'dispositifIds', queryName: 'dispositifFo', paramName: 'dispositif-fo', checkboxes: dispositifCheckboxes }),
            FiltreCheckboxes({ name: 'etatDossierIds', label: "État du dossier", valueName: 'etatDossierIds', queryName: 'etatDossierFo', paramName: 'etat-dossier-fo', checkboxes: etatDossiersCheckboxes }),
            FiltreRadioGroup({ name: 'subrogation', label: "Subrogation", paramName: 'subrogation', defaultValue: 'tous', options: [
                { label: "Oui", value: "oui" },
                { label: "Non", value: "non" },
                { label: "Tous", value: "tous" },
            ] }),
        ]
        
        if (import.meta.env.REACT_APP_RELANCE === 'true') {
            filtres.push(
                FiltreRadioGroup({
                    name: 'alerte',
                    label: "Dossier en alerte",
                    paramName: 'alerte',
                    defaultValue: '',
                    options: [
                        { label: "Oui", value: "oui" },
                        { label: "Non", value: "non" },
                        { label: "Tous", value: "" },
                    ]
                }),
                FiltreRadioGroup({
                    name: 'demande',
                    label: "Demande en cours de traitement par Atlas",
                    paramName: 'demande',
                    defaultValue: '',
                    options: [
                        { label: "Oui", value: "oui" },
                        { label: "Non", value: "non" },
                        { label: "Tous", value: "" },
                    ]
                }),
            )
        }
        return filtres
    }

    const colonnes = (query: DossiersSearchQuery): MyTableColumn[] => import.meta.env.REACT_APP_RELANCE === 'true' ? [
        ColonneNumeroFF,
        ColonneStagiaireFF,
        ColonneFormationEntrepriseFF,
        ColonneDateSourceFF(query),
        ColonneMontantAccordeFF,
        ColonnePrestataireSoldeFF,
    ] : [
        ColonneNumero,
        ColonneStagiaire,
        ColonneIntitule,
        ColonneDispositif,
        {
            id: "Entreprise",
            label: "Entreprise",
            value: row => row.EtablissementAdherent?.Name,
            wrap: true,
        },
        ColonneDateSource(query),
        ColonneEtat,
    ]

    const headerActions = import.meta.env.REACT_APP_MAP_CAPP !== 'false' && entreprise?.CertificationCA__c 
        ? <NewRequest menu="accueil" /> 
        : undefined

    const tableActions = (dossier: Dossier) => {
        const actions = []
        if (dossier.EtatDossierFORef?.Id === ETAT_DOSSIER_BROUILLON) {
            actions.push(
                <AtlasButton
                    key="supprimer"
                    icon="trash"
                    level={ 3 }
                    onClick={() => setShowDeleteModal({show: true, dossier: dossier})}
                >Supprimer</AtlasButton>
            )
        }
        actions.push(
            <XLinkDossier key="voirDossier" dossier={dossier}
                disabled={ (dossier.SourceFo !== 'MAP' && dossier.EtatDossierFORef?.Id === ETAT_DOSSIER_EN_COURS) || (
                    dossier.EtatDossierFORef?.Id === ETAT_DOSSIER_ANNULE || !dossier.SubrogationPaiement__c) } />
        )
        if (dossier.DispositifFO__c === DISPOSITIF_CONTRAT_APPRENTISSAGE && dossier.EtatDossierFORef?.Id === ETAT_DOSSIER_ACCORDE) {
            actions.push(<DossierDeposerFactureSelfe key="deposertFacture" icon="bill" level={3} detail={ dossier } mode="proforma" />)
        }
        return actions
    }

    const newQuery = (params: URLSearchParams) => new DossiersSearchQueryImpl(params, { withOrganisme: true, withSubrogation: true })

    return (
        <RechercheBase<Dossier, DossiersSearchQueryImpl>
            name="MesDossiers"
            title="Dossiers"
            newQuery={ newQuery }
            filtres={ getFiltres }
            colonnes={ colonnes }
            tabs={ singleTab(getDossiers) }
            executeExport={ exportDossiers }
            getErrorRowsIndexes={
                import.meta.env.REACT_APP_RELANCE === 'true' ?
                (rows: Dossier[]) => new Set<number>(rows.map((row, index) => row.TypeAlerteOF__c ? index : -1).filter(index => index != -1)) :
                undefined
            }
            errorLabel="Ce dossier est en alerte"
            rowIcon={ (row: Dossier) => (row.DemandesEnCours ?? []).length > 0 ? {
                name: "hourglass",
                label: "En cours de traitement",
                tooltipContent: "Ce dossier est en cours de traitement chez Atlas"
            } : undefined }
            headerActions={ headerActions }
            tableActions={ tableActions }
            rightInfo={ <DelaiTraitementPrestataire /> }>
            { (showDeleteModal?.show === true && showDeleteModal?.dossier != null) &&
            <DeleteModal dossier={ showDeleteModal?.dossier } close={ () => setShowDeleteModal({ show: false }) } />
            }
        </RechercheBase>
    )
}

const ColonnePrestataireSoldeFF: MyTableColumn = {
    id: "solde",
    label: "Solde",
    nowrap: true,
    sortable: true,
    value: (row: DossierDetail) => [
        row.MontantSoldeOF__c != null ? <>{ formatCurrency(row.MontantSoldeOF__c) }&nbsp;<AtlasText small>OF/CFA</AtlasText></> : null,
    ].filter(x => x !== null),
}