import { Form, SetValueOptions, getFieldState, useForm } from '@dsid-opcoatlas/reform'
import { StringSchema } from '@dsid-opcoatlas/yop'
import { DetailFormationCampus, SessionFormation, getFormation, patchSession, postSession } from 'api/formationAPI'
import { AdresseEntreprise, getAdressesEntreprise } from 'api/profilAPI'
import { MODALITE_STAGE_EN_LIGNE, TYPE_STAGE_INTER, TYPE_STAGE_INTRA } from 'api/references'
import useApiState from 'api/useApiState'
import { parseApiDate } from 'api/util'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasFieldText, AtlasFlex, AtlasHeading, AtlasInfo, AtlasLoading, MyForm, MyListInput, MyTags } from 'atlas-ds'
import { decodeFromCsv } from 'components/csv/Csv'
import { date2APIDate, formatDate } from "components/format/Format"
import Button from 'components/reform/Button'
import ErrorBanner from 'components/reform/ErrorBanner'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import CheckboxField from 'components/reform/inputs/CheckboxField'
import CodePostalCommuneField from 'components/reform/inputs/CodePostalCommuneField'
import DateField from 'components/reform/inputs/DateField'
import NumberField from 'components/reform/inputs/NumberField'
import RadioField from 'components/reform/inputs/RadioField'
import TextField from 'components/reform/inputs/TextField'
import TimeField from 'components/reform/inputs/TimeField'
import { AdresseValidationFieldsParams, Ignored, OptionalBoolean, OptionalDate, OptionalString, OptionalTime, RequiredNumber, RequiredString, optionalObject, requiredObject } from 'components/yop/constraints'
import { addYears, isAfter, isBefore, isEqual, isValid, lastDayOfYear, startOfDay } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { nil } from 'slices/dossierCommon'


const createSession = (session?: SessionFormation | null) => { return {
    type: session?.Type__c ?? null,
    modalite: session?.Modalite__c ?? null,
    sessionGarantie: (session?.SessionGarantie__c ?? false) as (boolean | null),
    nbPlacesTotalAtlas : session?.NbPlacesTotalAtlas__c ?? null,
    dateDebut: parseApiDate(session?.DateDebut__c),
    heureDebut: session?.HeureDebut__c ?? null,
    dateFin: parseApiDate(session?.DateFin__c),
    heureFin: session?.HeureFin__c ?? null,
    datesSession: decodeFromCsv(session?.DateSession__c).map(date => new Date(date)),
    adresse: session?.Adresse ?? null,
}}
type Session = ReturnType<typeof createSession>


interface InformationsSessionFormProps {
    onSuccess: (session: SessionFormation) => void,
    onCancel: () => void
    formation: DetailFormationCampus
    session?: SessionFormation
    modeDupliquer?: boolean
    heading?: boolean
}


export default function InformationsSessionForm(props: InformationsSessionFormProps) {

    const [error, setError] = useState<string>()
    const [newAdresse, setNewAdresse] = useState<boolean>(false)
    
    const [{ value: formation, pending }, withGetFormation] = useApiState(getFormation, { value: props.formation, pending: false, error: null })
    useEffect(() => {
        if (formation!.Type__c == null || formation!.Modalite__c == null)
            withGetFormation(formation!.IdHeroku)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withGetFormation])

    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const addDateSessionInput = useRef<HTMLInputElement | null>(null)

    const getAdresses = (recherche: string) => getAdressesEntreprise(entreprise?.IdHeroku!, recherche)

    const initialValues: Session = createSession(props.session)

    const stageInterNonEnLigne = (session: Session | null | undefined) => {
        return session?.type === TYPE_STAGE_INTER && session?.modalite != null && session?.modalite !== MODALITE_STAGE_EN_LIGNE
    }

    const dateDebutValidite = parseApiDate(formation?.DateDebutValidite__c)
    const dateFinValidite = parseApiDate(formation?.DateFinValidite__c)

    const validationSchema = requiredObject<Session>({
        type: RequiredString,
        modalite: RequiredString,
        sessionGarantie: OptionalBoolean,
        nbPlacesTotalAtlas : RequiredNumber
            .min(1)
            .max(formation?.Module?.StagiaireMaxInter__c ?? Number.MAX_SAFE_INTEGER)
            .ignoredIf<Session>(context => !stageInterNonEnLigne(context.parent)),
        dateDebut: OptionalDate
            .requiredIf<Session>(context => stageInterNonEnLigne(context.parent))
            .test<Session>(context => {
                if (context.parent!.type === TYPE_STAGE_INTER && isBefore(context.value!, startOfDay(new Date())))
                    return context.createError("La date de début de session ne doit pas être antérieure à la date du jour dans le cas d'une formation de type \"Inter\"")
                
                if (isBefore(context.value!, dateDebutValidite!))
                    return context.createError(`La date de début de session ne doit pas être antérieure à la date de début de validité du stage ("${ formatDate(dateDebutValidite) }") dans le cas d'une formation de type "Inter"`)

                if (isAfter(context.value!, dateFinValidite!))
                    return context.createError(`La date de début de session ne doit pas postérieure à la date de fin de validité du stage : ${ formatDate(dateFinValidite) }`)

                if (context.parent?.datesSession?.find(date => isBefore(date, context.value!)))
                    return context.createError("Une date de formation est prévue avant cette date")

                return true
            }),
        heureDebut: OptionalTime
            .requiredIf<Session>(context => stageInterNonEnLigne(context.parent)),
        dateFin: OptionalDate
            .requiredIf<Session>(context => stageInterNonEnLigne(context.parent))
            .min<Session>(context => context.parent?.dateDebut)
            .test<Session>(context => {
                const dateMax = addYears(lastDayOfYear(dateFinValidite!), 1)
                if (isAfter(context.value!, dateMax))
                    return context.createError(`La date de session ne doit pas dépasser le ("${ formatDate(dateMax) }") de la date de fin de validité du stage`)
                return true
            }),
        heureFin: OptionalTime
            .requiredIf<Session>(context => stageInterNonEnLigne(context.parent))
            .test<Session>(context => {
                const dateDebut = context.parent?.dateDebut
                const heureDebut = context.parent?.heureDebut
                const dateFin = context.parent?.dateFin

                if (dateDebut != null && heureDebut != null && dateFin != null && isEqual(dateDebut, dateFin) &&
                    StringSchema.parseTime(context.value) <= StringSchema.parseTime(heureDebut))
                    return context.createError("L'heure de fin de session doit être après l'heure de début")

                return true
            }),
        datesSession: Ignored,
        adresse: optionalObject<AdresseEntreprise>({
            Adresse1__c: OptionalString.max(AdresseValidationFieldsParams.Apt),
            Adresse2__c: OptionalString.max(AdresseValidationFieldsParams.Bat),
            Adresse3__c: RequiredString.max(AdresseValidationFieldsParams.Apt),
            Adresse4__c: OptionalString.max(AdresseValidationFieldsParams.Bat),
            CodePostal__c: RequiredString.max(AdresseValidationFieldsParams.Cp).matches(
                AdresseValidationFieldsParams.CpRegex,
                AdresseValidationFieldsParams.CpMessage
            ),
            Ville__c: RequiredString.max(AdresseValidationFieldsParams.Com),
            Departement__c: Ignored,
            Region__c: Ignored,
        }).requiredIf<Session>(context => context.parent?.modalite != null && context.parent?.modalite !== MODALITE_STAGE_EN_LIGNE),
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            const session = context.values!
            
            const sessionFormation = {
                ...props.session,
                
                Type__c: session.type!,
                Modalite__c: session.modalite!,
                SessionGarantie__c: session.sessionGarantie,
                NbPlacesTotalAtlas__c: session.nbPlacesTotalAtlas ?? 0,
                DateDebut__c: session.dateDebut ? date2APIDate(session.dateDebut) : null,
                HeureDebut__c: session.heureDebut,
                DateFin__c: session.dateFin ? date2APIDate(session.dateFin) : null,
                HeureFin__c: session.heureFin,
                DateSession__c: session.datesSession.map(date => date2APIDate(date)).join(','),
                Adresse: session.adresse ?? {
                    Adresse1__c: '',
                    Adresse2__c: '',
                    Adresse3__c: '',
                    Adresse4__c: '',
                    CodePostal__c: '',
                    Ville__c: '',
                    Departement__c: '',
                    Region__c: ''
                },
                
                ...(props.session == null ? {
                    NbPlacesDisponibles__c: session.nbPlacesTotalAtlas ?? 0,
                    NbPlacesOccupees__c: 0,
                } : {})
            }

            if (props.session?.IdHeroku && !props.modeDupliquer) {
                patchSession(props.session.IdHeroku, sessionFormation)
                    .then(session => {
                        props.onSuccess(session)
                    })
                    .catch(error => {
                        setError(error.response?.data?.message ?? error.message ?? "Une erreur inattendue est survenue")
                    })
                    .finally(() => {
                        context.setSubmitting(false)
                    })
            }
            else {
                delete sessionFormation.IdHeroku // pour le mode duplication

                postSession(formation!.IdHeroku, sessionFormation)
                    .then(session => {
                        props.onSuccess(session)
                    })
                    .catch(error => {
                        setError(error.response?.data?.message ?? error.message ?? "Une erreur inattendue est survenue")
                    })
                    .finally(() => {
                        context.setSubmitting(false)
                    })
            }
        },
    })

    const adresseFieldName = "adresse"
    const adresseFieldState = getFieldState<AdresseEntreprise | null>(form, adresseFieldName)
    const formatAdresse = (adresse: AdresseEntreprise | null) => {
        if (adresse == null)
            return ""
        return `${ adresse.Adresse3__c ?? '' } ${ adresse.CodePostal__c ?? '' } ${ adresse.Ville__c ?? '' }`.trim()
    }

    const onTypeChange = (value: string | null) => {
        if (value === TYPE_STAGE_INTRA) {
            resetDateHeureEtNbPlace()

            if (form.values?.modalite === MODALITE_STAGE_EN_LIGNE)
                form.setValue("modalite", null, SetValueOptions.Untouch | SetValueOptions.Validate)
        }
    }

    const onModaliteChange = (value: string | null) => {
        if (value === MODALITE_STAGE_EN_LIGNE) {
            resetDateHeureEtNbPlace()
            form.setValue("adresse", null, SetValueOptions.Untouch | SetValueOptions.Validate)
        }
    }

    const resetDateHeureEtNbPlace = () => {
        form.setValue("nbPlacesTotalAtlas", null, SetValueOptions.Untouch)
        form.setValue("dateDebut", null, SetValueOptions.Untouch)
        form.setValue("heureDebut", null, SetValueOptions.Untouch)
        form.setValue("dateFin", null, SetValueOptions.Untouch)
        form.setValue("heureFin", null, SetValueOptions.Untouch | SetValueOptions.Validate)
    }

    const addDateSession = () => {
        const date = addDateSessionInput.current?.valueAsDate
        if (isValid(date)) {
            const datesSession = (form.values?.datesSession ?? []).concat([date!]).sort()
            form.setValue("datesSession", datesSession, true)
            addDateSessionInput.current!.value = ''
        }
    }

    const onNewAdresse = () => {
        const newAdresse: AdresseEntreprise = {
            Adresse1__c: null,
            Adresse2__c: null,
            Adresse3__c: nil as string,
            Adresse4__c: null,
            CodePostal__c: nil as string,
            Ville__c: nil as string,
            Departement__c: nil as string,
            Region__c: nil as string
        }
        form.setValue("adresse", newAdresse, true)
        setNewAdresse(true)
    }

    const sessionReservee = (props.session?.NbPlacesOccupees__c ?? 0) > 0

    return (
        <section key="informationsPrincipalesForm">
            <AtlasLoading loading={pending}>
                {props.heading && <AtlasHeading tag="h2" size="m">Informations principales</AtlasHeading>}

                <AtlasLoading.Loaders>
                    <AtlasFlex column gap="m">
                        <AtlasLoading.Loader />
                        <AtlasLoading.Loader />
                        <AtlasLoading.Loader />
                    </AtlasFlex>
                </AtlasLoading.Loaders>

                <Form context={ form } noValidate>
                    <MyForm>
                        <RadioField
                            name="type"
                            label="Type de formation"
                            onChange={ onTypeChange }
                            options={ (refs?.REF_CAMPUS_TYPE ?? [])
                                .filter(r => formation?.Type__c?.split(';').includes(r.Id))
                                .map(formation => ({
                                    value: formation.Id,
                                    label: formation.Libelle__c
                                }))
                            } />
                        
                        <RadioField
                            name="modalite"
                            label="Modalités"
                            onChange={ onModaliteChange }
                            options={ (refs?.REF_CAMPUS_MODALITE ?? [])
                                .filter(r => formation?.Modalite__c?.split(';')?.includes(r.Id))
                                .filter(v => !(form.values?.type === TYPE_STAGE_INTRA && v.Id === MODALITE_STAGE_EN_LIGNE))
                                .map(formation => ({
                                    value: formation.Id,
                                    label: formation.Libelle__c
                                }))
                            } />

                        <MyForm.Grid>
                            <CheckboxField name="sessionGarantie" label="Session garantie" />

                            { stageInterNonEnLigne(form.values) && <>
                            <MyForm.Field alone>
                                <NumberField name="nbPlacesTotalAtlas" label="Nombre de places dédiées à Atlas" />
                            </MyForm.Field>
                            
                            <MyForm.Fieldset legend="Dates de la session">
                                <MyForm.Grid>
                                    <DateField
                                        name="dateDebut"
                                        label="Date de début de la session"
                                        disabled={ sessionReservee }
                                    />
                                    <TimeField
                                        name="heureDebut"
                                        label="Heure de début de la session"
                                        disabled={ sessionReservee }
                                    />
                                    <DateField
                                        name="dateFin"
                                        label="Date de fin de la session"
                                        disabled={ sessionReservee }
                                    />
                                    <TimeField
                                        name="heureFin"
                                        label="Heure de fin de la session"
                                        disabled={ sessionReservee }
                                    />

                                    <MyListInput
                                        onAdd={addDateSession}
                                        input={<AtlasFieldText
                                            ref={addDateSessionInput}
                                            name="date-session"
                                            label="Détail des dates de formations pour cette session (optionel)"
                                            type="date"
                                            min={ form.values?.dateDebut != null ? date2APIDate(form.values.dateDebut) : undefined }
                                            max={ form.values?.dateFin != null ? date2APIDate(form.values.dateFin) : undefined }
                                        />}
                                        values={<MyTags>
                                            { (form.values?.datesSession ?? []).map((dateSession, index) => <MyTags.Item
                                                key={'dateSession-' + index}
                                                light
                                                delete={{
                                                    ariaLabel: `Retirer la date ${dateSession}`,
                                                    onDelete: () => form.setValue("datesSession", form.values!.datesSession.filter((_, i) => i !== index), true)                                            }}
                                            >{ formatDate(dateSession) }</MyTags.Item> )}
                                        </MyTags>}
                                    />
                                </MyForm.Grid>
                            </MyForm.Fieldset>
                            </> }

                            { form.values?.modalite != null && form.values?.modalite !== MODALITE_STAGE_EN_LIGNE &&
                            <MyForm.Fieldset legend="Adresse de la session">
                                { !sessionReservee &&
                                
                                newAdresse ?
                                <AtlasInfo title="Ajout d'une nouvelle adresse">
                                    <p>
                                        Vous n'avez pas trouvé ce que vous cherchiez ?<br/>
                                        Utilisez les champs ci-dessous pour renseigner vos informations.
                                    </p>
                                    <AtlasButton icon="search" level={3} onClick={() => {
                                        form.setValue(adresseFieldName, initialValues.adresse, SetValueOptions.Untouch | SetValueOptions.Validate)
                                        setNewAdresse(false)
                                    }}>Refaire une recherche</AtlasButton>
                                </AtlasInfo> :
                                
                                <AutocompleteBaseField<AdresseEntreprise>
                                    label="Choix du lieu"
                                    error={ adresseFieldState.error }
                                    required={ adresseFieldState.constraints.required }
                                    placeholder="Recherche par rue ou commune"
                                
                                    search={ getAdresses }
                                    addButton={{ label: "Nouvelle adresse", onClick: onNewAdresse }}

                                    optionFor={ adresse => ({ id: adresse.Id ?? "", content: formatAdresse(adresse) })}
                                    onSelect={ adresse => form.setValue(adresseFieldName, adresse, true) }
                                />
                                }

                                <MyForm.Grid>
                                    <TextField disabled={ !newAdresse } name="adresse.Adresse1__c" label="Appartement" />
                                    <TextField disabled={ !newAdresse } name="adresse.Adresse2__c" label="Batiment" />
                                    <TextField disabled={ !newAdresse } name="adresse.Adresse3__c" label="N° et voie" />
                                    <TextField disabled={ !newAdresse } name="adresse.Adresse4__c" label="Lieu dit / BP" />
                                    <CodePostalCommuneField disabled={ !newAdresse } name="adresse" label="Code postal et commune" 
                                        codePostalName="CodePostal__c" communeName="Ville__c"
                                        departementName="Departement__c" regionName="Region__c" />
                                </MyForm.Grid>
                            </MyForm.Fieldset>
                            }
                        </MyForm.Grid>

                        <ErrorBanner title="Erreur lors de la sauvegarde de la session" message={ error } />

                        <MyForm.Actions>
                            <Button submit={ true } spinner={{spinning: form.submitting }}>Valider</Button>
                            <Button level={ 2 } onClick={ () => props.onCancel() }>Annuler</Button>
                        </MyForm.Actions>
                    </MyForm>
                </Form>
            </AtlasLoading>
        </section>
    )
}
