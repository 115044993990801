import store from 'app/store'
import axios from 'axios'
import { axiosMamcPrivate, getRequestConfig } from './baseAPI'
import { toSearchParams } from './util'
import { AppType } from 'slices/contextSlice'
import { TYPE_ENTREPRISE, TYPE_ORGANISME } from './references'

export interface EtablissementAdherent {
    SIRET__c: string
}

export interface ObjectID {
    Id: string
}

export interface EtablissementAdherentDetail extends EtablissementAdherent {
    Id: string
    Name: string
    TypeEnregistrement: string
    CodeAdherent__c: string
    SIREN__c: string
    SIRET__c: string
    RaisonSociale__c: string
    CodeIDCC__c: string
    CodeNAF__c: string
    Adresse1__c: string
    Adresse2__c: string
    Adresse3__c: string
    Adresse4__c: string
    BillingStreet: string
    BillingPostalCode: string
    BillingCity: string
    BillingCountry: string
    ParentId: string
    VV__c: boolean
    Branche__c: string
}

export interface Etablissement {
    IdHeroku: number
    Actif: boolean
    BillingCity: string
    BillingCountry: string
    BillingPostalCode: string
    BillingStreet: string
    Adresse1__c: string
    Adresse2__c: string
    Adresse3__c: string
    Adresse4__c: string
    CodeAdherent__c: string
    CodeEtablissement__c: string
    CodeIDCC__c: string
    CodeNAF__c: string
    Name: string
    Id: string
    SIREN__c: string
    SIRET__c: string
    ParentId: string
    VV__c: boolean
    Branche__c: string
    VVAnneeN1__c: number
    VVAnneeN2__c: number
    VVBudgetAnneeN1__c: number
    VVBudgetAnneeN2__c: number
    VVEngageAnneeN1__c: number
    VVEngageAnneeN2__c: number
    VVFdgAnneeN1__c: number
    VVFdgAnneeN2__c: number
    VVSoldeAnneeN1__c: number
    VVSoldeAnneeN2__c: number
    VVVersementAnneeN1__c: number
    VVVersementAnneeN2__c: number
    Region__c: string
    TailleEntreprise__c: string
    NumberOfEmployees: number
    NumeroDA__c: string
    NomPrenomConseillerMyAtlas__c: string
    NomPrenomAnalysteMyAtlas__c: string
    NomPrenomDeveloppeurMyAtlas__c: string
    CertificationsOFCampus__c: string
    SiteInternetOFCampus__c: string
    DescriptionOFCampus__c: string
    OFCampus__c: boolean
    TiersFacilitateur__c: boolean
    Uai__c: string
    StatutQualiopi__c: string
    CertificationCA__c: boolean
    SituationJuridique__c: string
    TypeEnregistrement: string
    EtatEtablissement__c: string
}

export interface Formation {
    Id: string
    Title__c: string
    CodeRNCP__c: string
    CodeDiplome__c: string
    RncpEligible__c: boolean
    // France Competences
    CodeRncp__c: string
    Libelle__c: string
    AccesApprentissage__c: boolean
}

export interface EtablissementAdherentDetail2 extends EtablissementAdherentDetail {
    CodeIDCC__c: string
    CodeNAF__c: string
    Name: string
    SIREN__c: string
    VV__c: boolean
    Branche__c: string
    TypeEnregistrement: string
}

export interface EntrepriseContact {
    Id: string
    Salutation: string
    FirstName: string
    LastName: string
    Email: string
    Phone: string
}

export interface StagiaireContact {
    Id: string
    Salutation: string
    FirstName: string
    LastName: string
    Email: string
    DateNaissance__c: Date
}

export interface TuteurContact {
    Id: string
    Salutation: string
    FirstName: string
    LastName: string
    Email: string
    DateNaissance__c: Date
}


export async function getEtablissement(id: string): Promise<Etablissement> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<Etablissement>('/etablissement/' + id, requestConfig)
    return data
}

export async function getEtablissements(recherche: string, actif = true): Promise<Etablissement[]> {
    const requestConfig = await getRequestConfig()
    const params = toSearchParams({
        'siren': store.getState().contextState.entreprise?.SIREN__c,
        'page': '0',
        'par-page': '400',
        'type': 'EtablissementClient',
        'recherche': recherche,
        'actif': actif.toString(),
    })
    const { data } = await axios.get<Etablissement[]>(`/etablissements/recherche?${params.toString()}`, requestConfig)
    return data
}

export async function getEtablissementsPrestataire(recherche: string, actif = true): Promise<Etablissement[]> {
    const requestConfig = await getRequestConfig()
    const params = toSearchParams({
        'siren': store.getState().contextState.entreprise?.SIREN__c,
        'page': '0',
        'par-page': '400',
        'recherche': recherche,
        'actif': actif.toString(),
    })
    const { data } = await axios.get<Etablissement[]>(`/etablissements/of/recherche?${params.toString()}`, requestConfig)
    return data
}

export async function getContacts(idetablissement: string, recherche: string): Promise<EntrepriseContact[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<any>('/etablissements/' + idetablissement + '/contacts?page=0&recherche=' + encodeURIComponent(recherche), requestConfig)
    const contacts = (data.Elements ?? []).map((c: any) => ({
        Id: c.Id,
        Salutation: '' + (c.Civilite?.Id ?? ''),
        FirstName: c.Prenom,
        LastName: c.Nom,
        Email: c.Email,
        Phone: c.Telephone,
    })) as EntrepriseContact[]
    contacts.sort((a, b) => (a.LastName ?? '').localeCompare(b.LastName ?? '') || (a.FirstName ?? '').localeCompare(b.FirstName ?? ''))
    return contacts
}

export async function getStagiaires(identreprise: string, recherche: string): Promise<StagiaireContact[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<StagiaireContact[]>('/entreprises/' + identreprise + '/stagiaires?page=0&tri=LastName&recherche=' + encodeURIComponent(recherche), requestConfig)
    return data
}

export async function getTuteurs(identreprise: string, recherche: string): Promise<TuteurContact[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<TuteurContact[]>('/entreprises/' + identreprise + '/tuteurs?page=0&tri=LastName&recherche=' + encodeURIComponent(recherche), requestConfig)
    return data
}

export interface OFSearchOptions {
    prestataire?: boolean
    tiersFacilitateur?: boolean
    tous?: boolean
    certificationCa?: boolean
    actif?: boolean
}

export async function getOrganismesFormation(recherche: string, options: OFSearchOptions = { actif: true }): Promise<Etablissement[]> {
    const { app, entreprise } = store.getState().contextState
    const requestConfig = await getRequestConfig()
    const params = {
        page: '0',
        tri: 'Name',
        recherche: recherche,
        tous: options.tous?.toString(),
        'tiers-facilitateur': options.tiersFacilitateur?.toString(),
        'certification-ca': options.certificationCa?.toString(),
        actif: options.actif?.toString(),
    } as any
    if (app === AppType.Prestataire) {
        params.siren = entreprise?.SIREN__c
    }
    const searchParams = toSearchParams(params)
    const { data } = await axios.get<Etablissement[]>(`/etablissements/of/recherche?${searchParams.toString()}`, requestConfig)
    return data
}


export async function getFormationLBA(recherche: string, niveau?: number): Promise<Formation[]> {
    const requestConfig = await getRequestConfig()
    const params = toSearchParams({
        'recherche': recherche,
        'niveau': niveau?.toFixed(0),
    })
    const { data } = await axios.get<Formation[]>(`/dossiers/capp/lba/formations?${params.toString()}`, requestConfig)
    // TODO: utiliser un vrai Id si possible...
    data.forEach(formation => formation.Id = formation.CodeRNCP__c)
    return data
}

export async function getFormationFC(recherche: string, niveau?: number): Promise<Formation[]> {
    const requestConfig = await getRequestConfig()
    const params = toSearchParams({ 
        "code-rncp": recherche,
        // niveau: niveau?.toFixed(0),
     })
    const { data } = await axios.get<Formation[]>(`/dossiers/capp/formations-rncp?${params.toString()}`, requestConfig)
    // TODO: utiliser un vrai Id si possible...
    data.forEach(formation => formation.Id = formation.CodeRncp__c)
    return data
}

export async function getOrganismesFormationParSiren(siren?: string): Promise<Etablissement[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<Etablissement[]>('/etablissements/of/recherche?page=0&par-page=100&tri=Name&siren=' + siren, requestConfig)
    return data
}

type VerifierNIRResult = {
    success: boolean
    message?: string
}

export async function verifierNIR(nom: string, nir: string) {
    const urlParams = window && window.location && window.location.search ? new URLSearchParams(window.location.search) : null
    const requestConfig = await getRequestConfig()
    // Ajoute un paramètre pour pouvoir tester les cas d'erreur de validation
    const params = new URLSearchParams()
    if (urlParams?.has('testNIR')) {
        params.append('test', urlParams!.get('testNIR')!)
        requestConfig.params = params
    }
    const { data } = await axios.post<VerifierNIRResult>('/stagiaires/verifier-nir', { Nom: nom, Nir: nir }, requestConfig)
    return data
}

export async function getEntrepriseMamc(recherche: string, actif = true ): Promise<Etablissement[]> {
    const requestConfig = await getRequestConfig()
    const params = toSearchParams({
        'page': '0',
        'par-page': '400',
        'type': TYPE_ENTREPRISE,
        'recherche': recherche,
        
    })
    const { data } = await axiosMamcPrivate.get<Etablissement[]>(`/entreprises/recherche?${params.toString()}`, requestConfig)
    return data
}

export async function getOrganismeMamc(recherche: string, actif = true ): Promise<Etablissement[]> {
    const requestConfig = await getRequestConfig()
    const params = toSearchParams({
        'page': '0',
        'par-page': '400',
        'type': TYPE_ORGANISME,
        'recherche': recherche,
        
    })
    const { data } = await axiosMamcPrivate.get<Etablissement[]>(`/entreprises/recherche?${params.toString()}`, requestConfig)
    return data
}

export async function getTableauToken(): Promise<any> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<any[]>('/tableau/token', requestConfig)
    return data
}


