import { unwrapResult } from '@reduxjs/toolkit'
import { DiagFacteurDTO, getFacteur } from 'api/diagatlasAPI'
import { useAppDispatch } from 'app/store'
import { AtlasFlex, AtlasHeading, AtlasHtml, AtlasSpacer, MyEvolution, MyEvolutionList, MyForm, MyModal, direction } from 'atlas-ds'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import DiagLayout from './DiagLayout'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'

interface MonDiagnosticFacteursProps {
    type: string
    categories: string[]
    step: number
}

export default function MonDiagnosticFacteurs(props: MonDiagnosticFacteursProps) {

    const dispatch = useAppDispatch()
    const { diagnostic, saving } = useDiagnostic(props.step, 'scenarios-type', { categorie: props.type })
    const navigation = useDiagnosticNavigation()

    const [nextStep, setNextStep] = useState(0)
    const [ showFacteur, setShowFacteur ] = useState<DiagFacteurDTO | null>(null)

    const onChange = (scenario: any, direction: direction) => {
        dispatch(diagnosticSliceActions.updateScenario({ facteur: scenario.Scenario.Facteur.Id, categorie: (direction+3).toString() }))
    }

    const facteurs = props.categories
        .map(categorie => {
            const cat = diagnostic?.scenarios?.find(s => s.Scenario.Facteur.Categorie.Code === categorie && s.Scenario.Facteur.Type === props.type)?.Scenario?.Facteur?.Categorie
            return cat ? { 
                categorie: cat.Code, 
                libelle: cat.Libelle, 
                description: cat.Description,
                facteurs: diagnostic?.scenarios?.filter(s => s.Scenario.Facteur.Categorie.Code === categorie && s.Scenario.Facteur.Type === props.type)
            } : null
        })
        .filter(c => !!c)

    const saveFacteursIfNecessary = (nextStep: number, next: () => void) => {
        const etape = nextStep > (diagnostic?.etape ?? 1) ? nextStep : (diagnostic?.etape ?? 1)
        setNextStep(nextStep)
        dispatch(diagnosticSliceActions.save({ diagnostic: { ...diagnostic, etape } as any, reset: false }))
            .then(unwrapResult)
            .then(next)
        return true
    }

    const saveFacteurs = (nextStep: number) => {
        saveFacteursIfNecessary(nextStep, () => navigation.navigateTo(nextStep))
    }

    const showInfoFacteur = async (facteurId: string) => {
        const facteur = await getFacteur(facteurId)
        setShowFacteur(facteur)
    }

    return <DiagLayout step={props.step} stepMax={diagnostic?.etape ?? 1}
        navigationGuard={saveFacteursIfNecessary}
        headingTitle={ `Les facteurs de mutation` }
        headingInfoBlock={ <div><MyEvolutionList /><AtlasSpacer size="l"/></div> }>

        <AtlasFlex column gap="s">
            <p>Nous avons repéré 51 facteurs qui vont influencer le développement économique de votre entreprise sur les prochaines années.</p>
            <p>15 de ces facteurs dépendent uniquement de vos orientations stratégiques à moyen terme.</p>
            <p><strong>Partagez avec nous les tendances repérées au sein de votre entreprise sur ces facteurs !</strong></p>
            <p>Par défaut, chaque facteur est positionné sur la tendance repérée au sein de votre secteur d’activité.</p>
        </AtlasFlex>

        <AtlasSpacer size="l" />

        <AtlasFlex column gap="xl">
            { facteurs.map(f => <AtlasFlex key={ f!.categorie } column gap="m">
                <AtlasHeading tag="h3" size="s">{ f!.libelle }&nbsp;: { f!.description }</AtlasHeading>
                <AtlasFlex column gap="s">
                    { f!.facteurs?.map(s => <MyEvolution
                        key={ s.Scenario.Code }
                        direction={ parseInt(s.Scenario.Categorie)-3 as direction }
                        onClick={ () => showInfoFacteur(s.Scenario.Facteur.Id) }
                        onChange={ direction => onChange(s, direction) }
                    >{ s.Scenario.Libelle }</MyEvolution>) }
                </AtlasFlex>
            </AtlasFlex> ) }

            <p><i>Vous souhaitez consulter l'ensemble des facteurs de mutation qui risque d'influencer le développement économique de votre entreprise sur les prochaines années ?&nbsp;<Link to="/mon-diagnostic-facteurs">En savoir plus...</Link></i></p>
        </AtlasFlex>

        <AtlasSpacer size="l" />

        <MyForm>
            <MyForm.Actions>
                <Button
                    level={2}
                    disabled={ saving }
                    spinner={{ spinning: saving && nextStep < props.step }}
                    onClick={ () => saveFacteurs(props.step-1) }
                >Etape précédente</Button>
                <Button
                    disabled={ saving }
                    spinner={{ spinning: saving && nextStep > props.step }}
                    onClick={ () => saveFacteurs(props.step+1 ) }
                >Passer à l'étape suivante</Button>
            </MyForm.Actions>
        </MyForm>

        { showFacteur && <ShowFacteurModal facteur={ showFacteur } close={ () => setShowFacteur(null) } /> }
    </DiagLayout>
}

export function ShowFacteurModal(props: { facteur: DiagFacteurDTO, close: () => void }) {
    return (
        <MyModal
            label={ props.facteur.Libelle }
            cancelButton={ <Button onClick={ props.close }>Retour</Button> }
        >
            <AtlasHtml>
                <p>{ props.facteur.Description }</p>

                { /* props.facteur.LienURL1 && <p>Source: <a href={ props.facteur.LienURL1 } target="_blank" rel="noopener noreferrer">{ props.facteur.LienURL1 }</a></p> }
                { props.facteur.LienURL2 && <p>Source: <a href={ props.facteur.LienURL2 } target="_blank" rel="noopener noreferrer">{ props.facteur.LienURL2 }</a></p> */ }
            </AtlasHtml>
        </MyModal>
    )
}