import { entreprise } from 'api/entreprise'
import { TYPE_BRANCHE_ASSISTANCE, TYPE_BRANCHE_ASSURANCE, TYPE_BRANCHE_BANQUE_POPULAIRE, TYPE_BRANCHE_CAISSE_EPARGNE, TYPE_BRANCHE_CREDIT_MUTUEL } from 'api/references'
import { AtlasInfo, AtlasSpacer, AtlasValues, MyContainer } from 'atlas-ds'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { Fragment } from 'react'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapCAPPMaitres(props: RecapProps<DossierCAPP>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)

    const metadata = props.metadata
    const tuteursEtape = steps.indexOf(props.step) + 1
    const dossier = props.dossier
    //const condBranche = entreprise()?.Branche__c === TYPE_BRANCHE_CREDIT_MUTUEL || entreprise()?.Branche__c === TYPE_BRANCHE_CAISSE_EPARGNE || entreprise()?.Branche__c === TYPE_BRANCHE_BANQUE_POPULAIRE || entreprise()?.Branche__c === TYPE_BRANCHE_ASSURANCE || entreprise()?.Branche__c === TYPE_BRANCHE_ASSISTANCE
    const condBranche = false // Désactivation de la condition
    return <>
        {dossier?.maitre1 &&
            <Fragment key="maitre1">
                <RecapSection
                    title={`${props.step.title ?? 'Tuteur'}${dossier?.maitre2 ? ' n°1' : ''}`}
                    onModifier={() => navigateTo(dossier!.NumeroDossier__c!, tuteursEtape)}
                >
                    {condBranche && <>
                        <AtlasInfo type="warning" title="Attention">
                            Les fonds votés par le conseil d'administration d'Atlas mobilisés au titre de l'aide à la fonction
                            de maître d'apprentissage ont été totalement consommés pour cette année,
                            cette aide ne peut être allouée à votre entreprise pour ce contrat.
                        </AtlasInfo>
                        <AtlasSpacer size="xl" />
                    </>}
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValues.Grid>
                                <FormElements key="maitre1" dossier={dossier} metadata={metadata} path="maitre1" type="recap" />
                            </AtlasValues.Grid>
                        </AtlasValues>
                    </MyContainer>
                </RecapSection>
            </Fragment>
        }
        {dossier?.maitre2 &&
            <Fragment key="maitre2">
                <RecapSection
                    title={`${props.step.title ?? 'Tuteur'}${dossier?.maitre2 ? ' n°2' : ''}`}
                    onModifier={() => navigateTo(dossier!.NumeroDossier__c!, tuteursEtape)}
                >
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValues.Grid>
                                <FormElements key="maitre2" dossier={dossier} metadata={metadata} path="maitre2" type="recap" />
                            </AtlasValues.Grid>
                        </AtlasValues>
                    </MyContainer>
                </RecapSection>
            </Fragment>
        }
    </>
}
