import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { entreprise } from 'api/entreprise'
import { loadDossierAF, saveDossierAF, sendDossierAF } from 'api/dossierAPI'
import { DISPOSITIF_PACC_RH, INTITULE_STAGE_AUTRE_BRANCHE_ETUDE, INTITULE_STAGE_BRANCHE_ETUDE, TYPE_BRANCHE_BUREAU_ETUDE } from 'api/references'
import { createCerfaDpc } from 'pages/dossier/cerfa/CerfaDpc'
import { mapDTOToDossierAF, mapDossierAFToDTO, simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import { employeurMetadata } from 'pages/dossier/metadata/EmployeurMetadata'
import { DOCNAME_DPC, DOCTYPE_DPC, ElementContext, IgnoredFieldMetadata, ObjectMetadata, ignored, override, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_OF, moduleFields } from 'pages/dossier/metadata/ModulesMetadata'
import { salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import { ArrayFieldMetadata, ObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierModulesEditForm } from 'pages/dossier/DossierModulesEditForm'
import { DossierModulesElements, dossierModulesInitializer } from 'pages/dossier/elements/DossierModulesElements'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { DossierSalariesEditForm } from 'pages/dossier/DossierSalariesEditForm'
import { dossierMultiSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import { DossierSalariesTable } from 'pages/dossier/DossierSalariesTable'
import { exportDossier } from 'pages/dossier/ExportDossierDPC'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import { AppType } from 'slices/contextSlice'
import { BaseDossier, BaseDossierModule, DossierAF, DossierSalarie, createBaseDossierModule, createDossierAF, createDossierSalarie } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'


function createDossierSalariePACCRH() { return {
    ...createDossierSalarie(),
    heures: 0,
}}

type DossierPACCRH= DossierAF<DossierSalarie, BaseDossierModule>
function createDossierPCCRH(): DossierPACCRH { return {
    ...createDossierAF(DISPOSITIF_PACC_RH),
}}


const api: DossierAPI<DossierPACCRH> = {
    create: createDossierPCCRH,
    createModule: () => createBaseDossierModule(),
    createSalarie: createDossierSalariePACCRH,
    load: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        return mapDTOToDossierAF(MetadataPACCRH, dossierDTO)
    },
    loadWithPieces: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        return mapDTOToDossierAF(MetadataPACCRH, dossierDTO)
    },
    save: async (dossier: DossierPACCRH) => {
        const dossierDTO = mapDossierAFToDTO(MetadataPACCRH, dossier)
        const savedDossierDTO = await saveDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataPACCRH, savedDossierDTO)
    },
    send: async (dossier: DossierPACCRH) => {
        const dossierDTO = mapDossierAFToDTO(MetadataPACCRH, dossier)
        const sentDossierDTO = await sendDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataPACCRH, sentDossierDTO)
    },
    postSend: async (dossier: DossierPACCRH) => {
        await postSendDossier(MetadataPACCRH, dossier)
    },
}

const PACCRHModuleMetadata: ObjectMetadata<BaseDossierModule> = {
    formationInterne: ignored(moduleFields.formationInterne),  
    cfa: override(moduleFields.cfa, { props: { da: true }, section: SECTION_MODULE_OF }),
    subrogation: ignored(moduleFields.subrogation),
    intitulePrecis: SelectRefFieldMetadata({
        props: {
            label: 'Intitulé du stage',
            tooltip: TOOLTIPS.formation.diplomeIntitulePrecis, 
            options: _ => entreprise()?.Branche__c === TYPE_BRANCHE_BUREAU_ETUDE ? INTITULE_STAGE_BRANCHE_ETUDE : INTITULE_STAGE_AUTRE_BRANCHE_ETUDE
        },
        mapping: simpleMapping('IntituleProgramme__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne !== null,
    }).required().mutate(yop => yop.max(250)),
    dureeTotale: moduleFields.dureeTotale,
    dateDebutFormation: moduleFields.dateDebutFormation,
    dateFinFormation: moduleFields.dateFinFormation,
    coutPedagogique: moduleFields.coutPedagogique,
    typeFormation: ignored(moduleFields.typeFormation),
    commentaire: moduleFields.commentaire,
}

const PACCRHSalarieMetadata: ObjectMetadata<DossierSalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: salarieFields.prenom,
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    handicap: salarieFields.handicap,
    statut: salarieFields.statut,
    contrat: salarieFields.contrat,
    niveauFormation: salarieFields.niveauFormation,
    dateEntree: salarieFields.dateEntree,
    categorieAction: IgnoredFieldMetadata(),
    heures:  override(salarieFields.heures, { input: undefined, recap: undefined, export: undefined}),
    heuresFoad: IgnoredFieldMetadata(),
    coefficient: IgnoredFieldMetadata(),
    autreMetier: salarieFields.autreMetier,
    situationHandicap: IgnoredFieldMetadata(),
    heuresHTT: IgnoredFieldMetadata(),
    heuresAfest: IgnoredFieldMetadata(),
}

type DossierPACCRHLight = Omit<DossierPACCRH, 'pieces'>
const PACCRHMetadataLight: ObjectMetadata<DossierPACCRHLight> = {
    employeur: ObjectFieldMetadata(employeurMetadata),
    modules: ArrayFieldMetadata(PACCRHModuleMetadata),
    salaries: ArrayFieldMetadata(PACCRHSalarieMetadata),
    signature: ObjectFieldMetadata(signatureMetadata),
}
const PACCRHMetadata = PACCRHMetadataLight as ObjectMetadata<DossierPACCRH>

export const MetadataPACCRH = registerMetadata(AppType.Entreprise, {
    dispositif:  DISPOSITIF_PACC_RH,
    title: "Dépôt dossier Prestation d'accompagnement RH",
    pathname: '/nouveau-dossier-pacc-rh',
    steps: [
        { 
            title: 'Employeur',
            sections: [],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            formContent: DossierEmployeurElements,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Formation',
            sections: [],
            formContent: DossierModulesElements,
            editForm: DossierModulesEditForm,
            recap: RecapModules,
            initializer: dossierModulesInitializer,
            yopPath: 'modules',
            options: {
                minElements: 1,
            },
           
        }, {
            title: 'Salariés',
            sections: [],
            preFormContent: DossierSalariesTable,
            editForm: DossierSalariesEditForm,
            recap: RecapSalaries,
            initializer: dossierMultiSalariesInitializer,
            yopPath: 'salaries',
            options: {
                noFormHeading: true,
                minElements: { value: 1, message: 'Vous devez ajouter au moins un salarié' },
                noSaving: true,
                forceValidate: (dossier) => dossier.salaries?.length >= 1,
            },
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: DossierPiecesElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: PACCRHMetadata,
    exportProps: [{
        exportDossier: async (metadata, dossier) => createCerfaDpc(exportDossier(metadata, dossier)),
        documentType: DOCTYPE_DPC,
        documentName: DOCNAME_DPC,
    }],
})

