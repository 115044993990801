import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasFlex } from 'atlas-ds'
import BoiteAOutils from 'components/blocs/BoiteAOutils'
import DelaiTraitementPrestataire from 'components/recherche/DelaiTraitementPrestataire'
import Toolbox from 'components/Toolbox'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppType } from 'slices/contextSlice'
import { BaseDossier } from 'slices/dossierCommon'
import DelaiTraitement from './DelaiTraitement'
import DeleteModal from './DeleteModal'
import { ImprimerChanges, ImprimerDoc } from './ImprimerDoc'
import SuiviEtapes from './SuiviEtapes'
import { getDossierEtapeMax, useDossierNavigation } from './useDossierNavigation'
import AskCancelModal from './AskCancelModal'

interface DossierSideProps<D extends BaseDossier> {
    etapeCourante: number
    dossier: D | null
    navigationGuard: (next: () => void) => boolean
    metadata: FormMetadata<D>
}

export default function DossierSide<D extends BaseDossier>(props: DossierSideProps<D>) {

    const { app } = useSelector((state: RootState) => state.contextState)
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [cancelModel, setCancelModal] = useState(false)

    function goEtape(etape: number) {
        navigateTo(props.dossier!.NumeroDossier__c!, etape)
    }

    const etapeMax = getDossierEtapeMax(props.dossier?.NumeroDossier__c)

    const showDownload =  (!props.metadata.api.isEdit && import.meta.env.REACT_APP_ALLOW_DOSSIER_DOWNLOAD !== 'false')
        || (props.metadata.api.isEdit && import.meta.env.REACT_APP_ALLOW_EDIT_DOSSIER_DOWNLOAD)

    return <AtlasFlex column gap="l">
        <SuiviEtapes etapeCourante={ props.etapeCourante } etapeMax={ etapeMax ?? props.etapeCourante } 
            iddossier={ props.dossier?.NumeroDossier__c ?? '' } steps={ steps } goEtape={ goEtape } />

        { app === AppType.Prestataire ?
        <DelaiTraitementPrestataire />
        :
        <DelaiTraitement dispositifId={ props.dossier?.DispositifFO__c } />
        }

        { (props.metadata.toolbox || props.dossier?.NumeroDossier__c) && <Toolbox
            heading="Actions"
            items={<>
                { props.metadata.toolbox && props.metadata.toolbox(props.dossier!) }
                { showDownload && props.dossier?.NumeroDossier__c && props.metadata.exportProps && props.metadata.exportProps.length > 0 && <>
                    { props.metadata.exportProps.filter(md => md.ignoredIf?.(props.dossier!) !== true).map((md, index) => 
                        <ImprimerDoc key={`print${index}`} dossier={ props.dossier } dossierId={ props.dossier?.NumeroDossier__c! } metadata={ props.metadata } index={index} />
                    ) } 
                    </>
                }
                { showDownload && props.metadata.api.isEdit &&
                    <ImprimerChanges key="printChanges" dossier={ props.dossier } dossierId={ props.dossier?.NumeroDossier__c! } metadata={ props.metadata } index={undefined} />
                }
                { props.dossier?.NumeroDossier__c && !props.metadata.api.isEdit && <AtlasButton
                    onClick={ () => setShowDeleteModal(true) }
                    level={ 2 }
                >Supprimer ce brouillon</AtlasButton> }
                 { props.dossier?.NumeroDossier__c && props.metadata.api.isEdit && <AtlasButton
                    onClick={ () => setCancelModal(true) }
                    level={ 2 }
                >Annuler la correction en cours</AtlasButton> }
            </>}
        /> }

        { !props.metadata.options?.noToolbox && <BoiteAOutils filter={ [props.metadata.dispositif] } /> }

        { showDeleteModal && props.dossier &&
        <DeleteModal dossier={ props.dossier } close={ () => setShowDeleteModal(false) }/>
        }
        {cancelModel && <AskCancelModal dossierId={props.dossier?.Id} close={() => setCancelModal(false)} />}
    </AtlasFlex>
}
