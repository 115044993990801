import { downloadDiagnosticExportDoc } from 'api/diagatlasAPI'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasFlex, AtlasHtml, MyModal } from 'atlas-ds'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Diagnostic } from 'slices/diagatlas/diagnosticSlice'
import DiagDeleteModal from './DiagDeleteModal'
import DiagExportsButton from './DiagExportsButton'

export default function DiagPlanActionOperations(props: { diagnostic: Diagnostic | null }) {
    const [showAskExport, setShowAskExport] = useState(false)
    const [showAskDelete, setShowAskDelete] = useState(false)

    return <>
        <AtlasFlex gap="xl">
            <AtlasButton level={4} icon="download" onClick={ () => setShowAskExport(true) }>
                Exporter (PDF)
            </AtlasButton>
            <DiagExportsButton />
            <AtlasButton level={4} icon="trash" onClick={ () => setShowAskDelete(true) }>
                Supprimer Diagnostic et Plan d'action
            </AtlasButton>
        </AtlasFlex>

        { showAskExport && <DiagExportModal diagnostic={ props.diagnostic } close={ () => setShowAskExport(false) } /> }

        { showAskDelete && <DiagDeleteModal diagnostic={ props.diagnostic } close={ () => setShowAskDelete(false) } /> }
    </>
}

export function DiagExportModal(props: { diagnostic: Diagnostic | null, close: () => void }) {
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const [saving, setSaving] = useState(false)

    const saveExport = () => {
        setSaving(true)
        downloadDiagnosticExportDoc(props.diagnostic!, `Diagnotic ${entreprise!.Name}.pdf`)
            .then(props.close)
            .finally(() => setSaving(false))
    }

    return (
        <MyModal
            label="Exporter le diagnostic"
            onClose={ props.close }
            okButton={ <Button onClick={ saveExport } spinner={{ spinning: saving }}>Enregistrer</Button>}
            cancelButton={ <Button onClick={ props.close }>Retour</Button>}
        >
            <AtlasHtml>
                <p>Votre diagnostic et Plan d'action vont être générés au format PDF.</p>
                <p>Une copie est consultable à partir de la page d'accueil de votre compte myAtlas Entreprise ou à partir des "Actions préconisées".</p>
            </AtlasHtml>
        </MyModal>
    )
}