import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { WritableDraft } from 'immer'
import log from 'loglevel'
import { deleteDocument } from 'api/documentAPI'
import { getDossierSendStatus, DossierDetail } from 'api/dossierAPI'
import { TYPE_MODIFICATION_AVENANT, TYPE_MODIFICATION_CORRECTION } from 'api/references'
import { RootState } from 'app/rootReducer'
import { BaseDossier, BaseDossierModule, BaseDossierStagiaire, BaseDossierTuteur, DossierModification, nil } from './dossierCommon'


export interface DossierState<D extends BaseDossier = BaseDossier> {
    loading: boolean
    saving: boolean
    transmitting: boolean
    dossier: D | null
    dossierInitial: D | null
    error: any | null
    errorLink: {
        label: string
        link: string
    } | null
}

export interface DossierAPI<D extends BaseDossier> {
    isEdit?: boolean
    create: (initial?: any, storedState?: D | null) => D
    createModule?: (dossier?: D) => BaseDossierModule
    createSalarie: (dossier?: D) => BaseDossierStagiaire
    createTuteur?: (dossier?: D) => BaseDossierTuteur
    load: (dossierId: string, storedState?: D | null) => Promise<D>
    loadWithPieces: (dossierId: string, storedState?: D | null) => Promise<D>
    postEdit?: (dossier: D) => void
    save: (dossier: D, storedState?: D | null) => Promise<D>
    send: (dossier: D) => Promise<D>
    postSend: (dossier: D) => Promise<void>
}

const initialState: DossierState = { loading: false, saving: false, transmitting: false, dossier: null, dossierInitial: null, error: null, errorLink: null }

const convertErrorMessage = (e: any, method: ("send" | "update" | "delete")): Error => {
    if ((e.isAxiosError && e.response?.status === 409 && method === "send") ||
        (e.message && e.message.includes("409 CONFLICT") && e.message.includes("doublon"))) {
        e.message = "Votre dossier n'a pas été enregistré dans notre système car un autre dossier identique (même établissement, " +
            "même stagiaire, mêmes dates) est déjà présent et en cours de traitement. Si il s'agit d'un avenant, veuillez joindre " +
            "une pièce au dossier initial."
    }
    return e
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const create = createAsyncThunk('dossier/create',
    (api: DossierAPI<BaseDossier>, { getState }) => {
        const dossierState = (getState() as RootState).dossierState
        return api.create(undefined, dossierState.dossier)
    }
)

const load = createAsyncThunk('dossier/load',
    async ({ api, dossierId }: { api: DossierAPI<BaseDossier>, dossierId: string }, { getState }) => {
        const dossierState = (getState() as RootState).dossierState
        if (api.isEdit && dossierState.dossier) {
            return dossierState.dossier
        }
        return await api.load(dossierId, dossierState.dossier)
    }
)

const loadWithPieces = createAsyncThunk('dossier/loadWithPieces',
    async ({ api, dossierId }: { api: DossierAPI<BaseDossier>, dossierId: string }, { getState }) => {
        const dossierState = (getState() as RootState).dossierState
        return await api.loadWithPieces(dossierId, dossierState.dossier)
    }
)

const edit = createAsyncThunk('dossier/edit',
    async ({ api, dossierDetail, dateAvenant }: { api: DossierAPI<BaseDossier>, dossierDetail: DossierDetail, dateAvenant?: boolean }, { getState }) => {
        const dossierState = (getState() as RootState).dossierState
        const dossier = await api.loadWithPieces(dossierDetail.NumeroDossier__c, dossierState.dossier) as (BaseDossier & DossierModification)
        return dossier
    }
)

const save = createAsyncThunk('dossier/save',
    async ({ api, dossier, local }: { api: DossierAPI<BaseDossier>, dossier: BaseDossier, local?: boolean }, { getState, rejectWithValue }) => {
        const dossierState = (getState() as RootState).dossierState
        if (isEqual(dossierState.dossier, dossier) || local)
            return await new Promise<BaseDossier>((resolve) => setTimeout(() => resolve(dossier), 200))
        try {
            return await api.save(dossier, dossierState.dossier)
        } catch (e: any) {
            if (!e.response || !e.response.data) {
                throw e
            }
            return rejectWithValue(e.response.data)
        }
    }
)

const deletePiece = createAsyncThunk('dossier/deletePiece',
    async (docId: string, { getState }) => {
        const state = (getState() as RootState).dossierState
        await deleteDocument(state.dossier?.NumeroDossier__c ?? '', docId)
    }
)

const send = createAsyncThunk('dossier/send',
    async ({ api, dossier }: { api: DossierAPI<BaseDossier>, dossier: BaseDossier }, { rejectWithValue }) => {
        try {
            return await api.send(dossier)
        } catch (e: any) {
            if (!e.response || !e.response.data) {
                throw e
            }
            return rejectWithValue(e.response.data)
        }
    }
)

const checkStatus = createAsyncThunk('dossier/checkStatus',
    async ({ api, dossier }: { api: DossierAPI<BaseDossier>, dossier: BaseDossier }) => {
        for (let tryCount = 1; tryCount <= 10; tryCount++) {
            await sleep(5000)
            
            try {
                log.info(`Checking transmission status: ${dossier.Id} try ${tryCount}`)
                const status = await getDossierSendStatus(dossier.Id!)
                log.info(`Checked transmission status: ${dossier.Id} => ${status.EnCoursTransmissionSOR__c}`)
    
                if (!status.EnCoursTransmissionSOR__c) {
                    if (status.ErreurTransmissionSOR__c) {
                        throw new Error(status.ErreurTransmissionSOR__c)
                    }
                    if (!status.NumeroDossier__c || status.NumeroDossier__c.startsWith('B-')) {
                        throw new Error("Erreur de transmission du dossier")
                    }

                    dossier.NumeroDossier__c = status.NumeroDossier__c
                    await api.postSend(dossier)
                    
                    return status.NumeroDossier__c
                }
            }
            catch (e) {
                throw convertErrorMessage(e, 'send')
            }
        }
        
        throw new Error("Erreur de transmission du dossier")
    }
)

const reset = (state: WritableDraft<DossierState>, values?: Partial<DossierState>) => {
    Object.assign(state, { ...initialState, ...values })
}

const slice = createSlice({
    name: 'dossier',
    initialState: initialState,
    reducers: {
        clear(state) {
            reset(state)
        },

        updatePiece(state, action) {
            const piece = state.dossier?.pieces?.find(p => p.type === action.payload.type)
            if (piece && piece.obligatoire) {
                piece.Id = action.payload.Id
                piece.dateAjout = action.payload.dateAjout
                piece.fichier = action.payload.fichier
                piece.ContentVersionId = action.payload.ContentVersionId
                piece.uploadedToGed = action.payload.uploadedToGed
            } else {
                let index = state.dossier?.pieces?.findIndex(p => p.type === piece?.type)
                if (index == null || index < 0)
                    index = state.dossier?.pieces?.length
                state.dossier?.pieces?.splice(index!, 0, {
                    Id: action.payload.Id,
                    type: action.payload.type,
                    description: action.payload.description,
                    actif: action.payload.actif,
                    obligatoire: false,
                    dateAjout: action.payload.dateAjout,
                    fichier: action.payload.fichier,
                    ContentVersionId: action.payload.ContentVersionId,
                    uploadedToGed: action.payload.uploadedToGed,
                })
            }
            reset(state, { dossier: state.dossier })
        },

        transmitting(state, action) {
            reset(state, { dossier: state.dossier, transmitting: action.payload.transmitting, error: action.payload.error ?? null })
        },
    },
    extraReducers: (builder) => { builder
        .addCase(create.fulfilled, (state, action) => {
            reset(state, { dossier: action.payload })
        })

        .addCase(load.pending, (state, action) => {
            if (action.meta.arg.api.isEdit)
                reset(state, { dossier: state.dossier, dossierInitial: state.dossierInitial, loading: true })
            else
                reset(state, { loading: true })
        })
        .addCase(load.fulfilled, (state, action) => {
            const dossier = action.payload
            if (action.meta.arg.api.isEdit)
                reset(state, { dossier, dossierInitial: dossier, transmitting: dossier?.traitementEnCours === true })
            else
                reset(state, { dossier, transmitting: dossier?.traitementEnCours === true })
        })
        .addCase(load.rejected, (state, action) => {
            if (action.meta.arg.api.isEdit)
                reset(state, { dossier: state.dossier, error: action.error.message })
            else
                reset(state, { error: action.error.message })
        })

        .addCase(loadWithPieces.pending, (state, action) => {
            if (action.meta.arg.api.isEdit)
                reset(state, { dossier: state.dossier, dossierInitial: state.dossierInitial, loading: true })
            else
                reset(state, { loading: true })
        })
        .addCase(loadWithPieces.fulfilled, (state, action) => {
            const dossier = action.payload
            if (action.meta.arg.api.isEdit)
                reset(state, { dossier: action.payload, dossierInitial: state.dossierInitial, transmitting: dossier?.traitementEnCours === true })
            else
                reset(state, { dossier: action.payload, transmitting: dossier?.traitementEnCours === true })
        })
        .addCase(loadWithPieces.rejected, (state, action) => {
            if (action.meta.arg.api.isEdit)
                reset(state, { dossier: state.dossier, dossierInitial: state.dossierInitial, error: action.error.message })
            else
                reset(state, { error: action.error.message })
        })

        .addCase(edit.pending, (state, action) => {
            reset(state, { loading: true })
        })
        .addCase(edit.fulfilled, (state, action) => {
            const dossier = action.payload
            const dossierDetail = action.meta.arg.dossierDetail
            dossier.typeModificationContrat = action.meta.arg.dateAvenant ? TYPE_MODIFICATION_AVENANT : TYPE_MODIFICATION_CORRECTION
            dossier.numModificationContrat = typeof dossierDetail.NumModificationContrat__c === 'string' 
                ? parseInt(dossierDetail.NumModificationContrat__c) + 1
                : (dossierDetail.NumModificationContrat__c ?? 0) + 1
            if (dossier.typeModificationContrat === TYPE_MODIFICATION_AVENANT) {
                dossier.signature.nom = nil as string
                dossier.signature.date = nil as Date
                dossier.signature.lieu = nil as string
            }
            if (action.meta.arg.api.postEdit)
                action.meta.arg.api.postEdit(dossier)
            
            reset(state, { dossier, dossierInitial: dossier, transmitting: dossier?.traitementEnCours === true })
        })
        .addCase(edit.rejected, (state, action) => {
            reset(state, { error: action.error.message })
        })

        .addCase(save.pending, (state) => {
            reset(state, { dossier: state.dossier, dossierInitial: state.dossierInitial, saving: true })
        })
        .addCase(save.fulfilled, (state, action) => {
            reset(state, { dossier: action.payload, dossierInitial: state.dossierInitial })
        })
        .addCase(save.rejected, (state, action) => {
            const message = (action.payload as any)?.message ?? action.error.message;
            reset(state, { dossier: state.dossier, dossierInitial: state.dossierInitial, error: message })
        })

        .addCase(deletePiece.fulfilled, (state, action) => {
            const pieceObligatoire = state.dossier?.pieces?.find(p => p.Id === action.meta.arg && p.actif)
            if (pieceObligatoire) {
                pieceObligatoire.Id = ''
                pieceObligatoire.dateAjout = ''
                pieceObligatoire.fichier = ''
            }
            if (state.dossier) {
                state.dossier.pieces = [...(state.dossier.pieces?.filter(p => p.Id !== action.meta.arg || p.actif) ?? [])]
            }
        })
        .addCase(deletePiece.rejected, (state, action) => {
        })

        .addCase(send.pending, (state) => {
            reset(state, { dossier: state.dossier, transmitting: true })
        })
        .addCase(send.fulfilled, (state, action) => {
            reset(state, { dossier: action.payload, transmitting: action.payload.traitementEnCours === true })
        })
        .addCase(send.rejected, (state, action) => {
            const message = (action.payload as any)?.message ?? action.error.message;
            const errorLink = (action.payload as any)?.link;
            reset(state, { dossier: { ...state.dossier, traitementEnCours: false } as BaseDossier, error: message, errorLink })
        })

        .addCase(checkStatus.pending, (state) => {    
            log.info(`Pending transmission status: ${state.dossier?.Id}`)
            reset(state, { dossier: state.dossier, dossierInitial: state.dossierInitial, transmitting: true })
        })
        .addCase(checkStatus.fulfilled, (state, action) => {
            const numDossier = action.payload            
            log.info(`Transmission successful: ${numDossier}`)
            reset(state, { dossier: { ...state.dossier, NumeroDossier__c: numDossier, traitementEnCours: false } as BaseDossier, dossierInitial: state.dossierInitial })
        })
        .addCase(checkStatus.rejected, (state, action) => {
            log.info(`Transmission failed: ${state.dossier?.NumeroDossier__c}, ${action.error.message}`)
            const message = state.error ?? action.error.message;
            reset(state, { dossier: { ...state.dossier, traitementEnCours: false } as BaseDossier, dossierInitial: state.dossierInitial, error: message, errorLink: state.errorLink })        
        })
    }
})

export default slice

export const dossierSliceActions = {
    ...slice.actions,
    create,
    load,
    loadWithPieces,
    edit,
    save,
    deletePiece,
    send,
    checkStatus,
}
