import { ValidationContext } from '@dsid-opcoatlas/yop'
import { config } from 'api/config'
import { DossierAFDTO, DossierAFModuleAgoraDTO, loadAndMergeDossierPieces, loadDossierAF, saveDossierAF, sendDossierAF } from 'api/dossierAPI'
import { entreprise } from 'api/entreprise'
import { CONTEXTE_SPECIFIQUE_AIRBUS, CONTEXTE_SPECIFIQUE_AIRBUS_CPF, CONTEXTE_SPECIFIQUE_AIRBUS_FNE, DISPOSITIF_ACN, FORMATION_CERTIFIANTE_NON, MODALITE_STAGE_EN_LIGNE, OBJECTIF_GENERAL_FORMATION_COMPETENCES, OBJECTIF_GENERAL_OF_PERFECTIONNEMENT, TYPE_COUT_PEDAGOGIQUE, TYPE_FORMATION_INTER, TYPE_FORMATION_INTRA, TYPE_STAGE_INTER, TYPE_STAGE_INTRA, references } from 'api/references'
import { References } from 'api/referencesAPI'
import { getCampusSession } from 'api/stageAPI'
import { parseApiDate } from 'api/util'
import { formatAmount, formatAmountNumber, formatCurrency } from 'components/format/Format'
import { BooleanFieldMetadata } from 'components/metadata/BooleanFieldMetadata'
import { IgnoredDateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { ArrayFieldMetadata, ObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { TextFieldMetadata } from 'components/metadata/TextFieldMetadata'
import PseudoTextField from 'components/reform/inputs/PseudoTextField'
import { Ignored, RequiredBoolean } from 'components/yop/constraints'
import log from 'loglevel'
import { DossierACNCampusButtons, DossierACNCampusElements, DossierACNCampusStep } from 'pages/dossier-acn/DossierACNCampusElements'
import DossierACNEmployeurElements from 'pages/dossier-acn/DossierACNEmployeurElements'
import { DossierSalariesEditForm } from 'pages/dossier/DossierSalariesEditForm'
import { DossierSalariesTable } from 'pages/dossier/DossierSalariesTable'
import { exportAttestationFNE } from 'pages/dossier/ExportAttestationFNE'
import { exportDossier } from 'pages/dossier/ExportDossierDPC'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { createCerfaDpc } from 'pages/dossier/cerfa/CerfaDpc'
import { createCerfaFne } from 'pages/dossier/cerfa/CerfaFne'
import { DossierModulesElements, dossierModulesInitializer } from 'pages/dossier/elements/DossierModulesElements'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { dossierMultiSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import ExportTextField from 'pages/dossier/export/ExportTextField'
import { dateMapping, mapDTOToDossierAF, mapDossierAFToDTO, revert, simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import { employeurMetadata } from 'pages/dossier/metadata/EmployeurMetadata'
import { DOCNAME_DPC, DOCTYPE_DPC, ElementContext, IgnoredFieldMetadata, ObjectMetadata, override, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA, SECTION_MODULE_OF, moduleAgoraFields, moduleFields } from 'pages/dossier/metadata/ModulesMetadata'
import { salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import RecapTextField from 'pages/dossier/recap/RecapTextField'
import { AppType } from 'slices/contextSlice'
import { DossierAF, DossierSalarie, createDossierAF, createDossierModuleAgora, createDossierSalarie, nil } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'
import ModelePiece from '../../components/ModelePiece'


export const DOCTYPE_SUBVENTION_FNE = 'SUBVENTION FNE'

function createDossierACNModule(initial?: { campusSession: string }) { return { 
    ...createDossierModuleAgora({ 
        subrogation: true,
        formationCertifianteAgora: FORMATION_CERTIFIANTE_NON,
        categorieActionAgora: OBJECTIF_GENERAL_OF_PERFECTIONNEMENT,
        objectifAgora: OBJECTIF_GENERAL_FORMATION_COMPETENCES,
    }), 
    niveauVise: nil as string,
    contexteSpecifique: nil as string | null,
    reference: nil as string,
    referenceSource: nil as string,
    campusSession: (initial?.campusSession ?? nil) as string,
    supportCours: nil as (boolean | null),
    certification: nil as (boolean | null),
    certificationDisponible: nil as (boolean | null),
    certificationObligatoire: nil as (boolean | null),
    // Champs additionnels stage non editables
    coutStage: nil as (number | null),
    coutSupportCours: nil as (number | null),
    coutCertification: nil as (number | null),
    coutSupportCoursRevise: nil as (number | null),
    coutCertificationRevise: nil as (number | null),
    minStagiaires: nil as (number | null),
    maxStagiaires: nil as (number | null),
    dateDebutValidite: nil as (Date | null),
    dateFinValidite: nil as (Date | null),
    modalite: nil as (string | null),
    fne: nil as boolean
}}
type DossierACNModule = ReturnType<typeof createDossierACNModule>

export type DossierACNSalarie = Omit<DossierSalarie, 'heures' | 'heuresFoad'>

function createDossierACN(initial?: { campusSession: string }) { return {
    ...createDossierAF(DISPOSITIF_ACN) as DossierAF<DossierACNSalarie, DossierACNModule>,
    modules: [
        createDossierACNModule(initial)
    ],
}}
export type DossierACN = ReturnType<typeof createDossierACN>


const FORMATION_ERRORS: any = { 'branche_incorrecte': 'Votre branche n\'a pas accès à cette formation' }

const saveDossierACN = async (dossier: DossierACN, storedState?: DossierACN | null) => {
    if (dossier.modules?.[0]?.campusSession && !dossier.modules?.[0]?.reference) {
        log.debug(`Loading session ${dossier.modules?.[0]?.campusSession} from API`)
        const campusSession = await getCampusSession(dossier.modules?.[0]?.campusSession)

        if (!campusSession.Stage.Reference__c) {
            throw new Error('Le stage sélectionné n\'a pas de référence')
        }

        if (!dossier.modules || dossier.modules.length === 0) {
            dossier.modules = [createDossierACNModule()]
        }
        const module = dossier.modules[0]
        if (module) {
            const of = campusSession.Stage.EtablissementOF

            module.campusSession = campusSession.Id
            module.formationInterne = false
            module.cfa = {
                EtablissementOFId__c: of.Id,
                CodeEtablissementOF__c: of.CodeEtablissement__c,
                nom: of.Name,
                siret: of.SIRET__c,
                numeroUAI: of.UAI__c,
                numeroDA: of.NumeroDA__c,
                entreprise: false,
                etat: '', // of.EtatEtablissement__c,
                appartement: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse1__c ?? null,
                batiment: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse2__c ?? null,
                adresse: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse3__c ?? null,
                complement: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse4__c ?? null,
                codePostal: campusSession.Stage?.EtablissementOF?.Adresse?.CodePostal__c ?? null,
                commune: campusSession.Stage?.EtablissementOF?.Adresse?.Ville__c ?? null,
            }
            module.reference = campusSession.Stage.Reference__c
            module.intitulePrecis = campusSession.Stage.Libelle__c
            module.typeFormation = campusSession.Type__c === TYPE_STAGE_INTER ? TYPE_FORMATION_INTER : TYPE_FORMATION_INTRA
            module.subrogation = true
            // mod.niveauVise = stage.NiveauVise
            if (campusSession.Type__c === TYPE_STAGE_INTER) {
                module.typeFormation = TYPE_FORMATION_INTER
                module.coutPedagogique = { IdHeroku: null, montant: campusSession.Stage.CoutinterEtrRevise__c }
                module.coutStage = campusSession.Stage.CoutinterEtrRevise__c
                module.minStagiaires = campusSession.Stage.Module.StagiaireMinInter__c
                module.maxStagiaires = campusSession.Stage.Module.StagiaireMaxInter__c
            } else if (campusSession.Type__c === TYPE_STAGE_INTRA) {
                module.typeFormation = TYPE_FORMATION_INTRA
                module.coutPedagogique = { IdHeroku: null, montant: campusSession.Stage.CoutintraEtrRevise__c }
                module.coutStage = campusSession.Stage.CoutintraEtrRevise__c
                module.minStagiaires = campusSession.Stage.Module.StagiaireMinIntra__c
                module.maxStagiaires = campusSession.Stage.Module.StagiaireMaxIntra__c
            }
            module.coutSupportCours = campusSession.Stage.CoutSupportCours__c
            module.coutSupportCoursRevise = campusSession.Stage.CoutSupportDeCoursRevise__c
            module.coutCertification = campusSession.Stage.CoutCertification__c
            module.coutCertificationRevise = campusSession.Stage.CoutCertificationRevise__c
            module.supportCours = campusSession.Stage.SupportCours__c
            module.certificationObligatoire = campusSession.Stage.CertificationObligatoire__c
            module.certificationDisponible = campusSession.Stage.Certification__c
            if (module.certificationObligatoire)
                module.certification = true
            else if (!module.certificationDisponible)
                module.certification = false
            module.dureeTotale = campusSession.Stage.DureeHeures__c
            module.modalite = campusSession.Modalite__c
            if (campusSession.Type__c === TYPE_STAGE_INTER && campusSession.Modalite__c !== MODALITE_STAGE_EN_LIGNE) {
                if (campusSession.DateDebut__c)
                    module.dateDebutFormation = parseApiDate(campusSession.DateDebut__c)!
                if (campusSession.DateFin__c)
                    module.dateFinFormation = parseApiDate(campusSession.DateFin__c)!
            }
            if (campusSession.Stage.DateDebutValidite__c)
                module.dateDebutValidite = parseApiDate(campusSession.Stage.DateDebutValidite__c)!
            if (campusSession.Stage.DateFinValidite__c)
                module.dateFinValidite = parseApiDate(campusSession.Stage.DateFinValidite__c)!
        }
        return revert(dossier, MetadataACN)
    }

    const dossierDTO = mapDossierAFToDTO(MetadataACN, dossier)
    try {
        let savedDossierDTO = await saveDossierAF(dossierDTO)
        savedDossierDTO = await postLoadDossierACN(savedDossierDTO, storedState)
    
        const dossier = mapDTOToDossierAF(MetadataACN, savedDossierDTO)

        if (dossier.modules && dossier.modules.length > 0 && storedState && storedState.modules && storedState.modules.length > 0) {
            // Conserve les valeurs du stage
            const module = dossier.modules[0]
            const saved = storedState.modules[0]
            module.coutStage = saved.coutStage
            module.certificationDisponible = saved.certificationDisponible
            module.certificationObligatoire = saved.certificationObligatoire
            module.coutCertification = saved.coutCertification
            module.coutCertificationRevise = saved.coutCertificationRevise
            module.coutSupportCours = saved.coutSupportCours
            module.coutSupportCoursRevise = saved.coutSupportCoursRevise
            module.modalite = saved.modalite
            module.minStagiaires = saved.minStagiaires
            module.maxStagiaires = saved.maxStagiaires
            module.dateDebutValidite = saved.dateDebutValidite
            module.dateFinValidite = saved.dateFinValidite
        }

        return dossier
    } catch (err: any) {
        throw new Error(FORMATION_ERRORS[err.message] ?? err.message)
    }
}

const postLoadDossierACN = async (dossierDTO: DossierAFDTO, storedState?: DossierACN | null) => {
    let module = dossierDTO.Modules?.[0]
    const reference = module?.Reference__c

    if (dossierDTO.CampusSession__c) {
        const savedDossierModule = storedState?.modules?.[0]

        if (savedDossierModule?.reference !== reference || savedDossierModule?.coutStage == null || savedDossierModule?.modalite == null
            || savedDossierModule?.certificationObligatoire == null || savedDossierModule?.certificationDisponible == null
            || savedDossierModule?.dateDebutValidite == null || savedDossierModule?.dateFinValidite == null) {                
            log.debug(`Loading Campus stage ${dossierDTO.CampusSession__c} from API`)
            const campusSession = await getCampusSession(dossierDTO.CampusSession__c ?? '')
            
            if (!module) {
                if (dossierDTO.Modules === null) {
                    dossierDTO.Modules = []
                }
                const of = campusSession.Stage.EtablissementOF
                module = {
                    EtablissementOFId__c: of.Id,
                    CodeEtablissementOF__c: of.CodeEtablissement__c,
                    NomOF__c: of.Name,
                    SiretOF__c: of.SIRET__c,
                    UaiOF__c: of.UAI__c,
                    NdaOF__c: of.NumeroDA__c,
                    Adresse1OF__c: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse1__c,
                    Adresse2OF__c: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse2__c,
                    Adresse3OF__c: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse3__c,
                    Adresse4OF__c: campusSession.Stage?.EtablissementOF?.Adresse?.Adresse4__c,
                    CodePostalOF__c: campusSession.Stage?.EtablissementOF?.Adresse?.CodePostal__c,
                    CommuneOF__c: campusSession.Stage?.EtablissementOF?.Adresse?.Ville__c,
                    Reference__c: campusSession.Stage.Reference__c,
                    IntituleProgramme__c: campusSession.Stage.Libelle__c,
                    TypeFormation__c: campusSession.Type__c === TYPE_STAGE_INTER ? TYPE_FORMATION_INTER : TYPE_FORMATION_INTRA,
                    NombreHeureFormation__c: campusSession.Stage.DureeHeures__c,
                } as DossierAFModuleAgoraDTO
                dossierDTO.Modules.push(module)
            }
            module.SubrogationPaiement__c = true
            module.CoutSupportCours__c = campusSession.Stage.CoutSupportCours__c
            module.CoutSupportDeCoursRevise__c = campusSession.Stage.CoutSupportDeCoursRevise__c
            module.SupportCours__c = campusSession.Stage.SupportCours__c
            module.CoutCertification__c = campusSession.Stage.CoutCertification__c
            module.CoutCertificationRevise__c = campusSession.Stage.CoutCertificationRevise__c
            module.CertificationDisponible__c = campusSession.Stage.Certification__c
            module.CertificationObligatoire__c = campusSession.Stage.CertificationObligatoire__c
            if (module.CertificationObligatoire__c)
                module.Certification__c = true
            else if (!module.CertificationDisponible__c)
                module.Certification__c = false
            module.Modalite__c = campusSession.Modalite__c
            if (campusSession.Modalite__c === MODALITE_STAGE_EN_LIGNE) {
                module.CoutStage__c = module.TypeFormation__c === TYPE_FORMATION_INTER ? campusSession.Stage.CoutinterEtrRevise__c : campusSession.Stage.CoutintraEtrRevise__c
                module.MinStagiaires__c = null
                module.MaxStagiaires__c = null
            } else if (module.TypeFormation__c === TYPE_FORMATION_INTER) {
                module.CoutStage__c = campusSession.Stage.CoutinterEtrRevise__c
                module.MinStagiaires__c = campusSession.Stage.Module.StagiaireMinInter__c
                module.MaxStagiaires__c = campusSession.Stage.Module.StagiaireMaxInter__c
            } else if (module.TypeFormation__c === TYPE_FORMATION_INTRA) {
                module.CoutStage__c = campusSession.Stage.CoutintraEtrRevise__c
                module.MinStagiaires__c = campusSession.Stage.Module.StagiaireMinIntra__c
                module.MaxStagiaires__c = campusSession.Stage.Module.StagiaireMaxIntra__c
            }
            module.DateDebutValidite__c = campusSession.Stage.DateDebutValidite__c
            module.DateFinValidite__c = campusSession.Stage.DateFinValidite__c
            if (campusSession.Type__c === TYPE_STAGE_INTER && campusSession.Modalite__c !== MODALITE_STAGE_EN_LIGNE) {
                if (campusSession.DateDebut__c && !module.DateDebutFormation__c) {
                    module.DateDebutFormation__c = campusSession.DateDebut__c as unknown as Date
                }
                if (campusSession.DateFin__c && !module.DateFinFormation__c) {
                    module.DateFinFormation__c = campusSession.DateFin__c as unknown as Date
                }
            }

        } else if (module) {
            // Restore saved values
            module.CertificationDisponible__c = savedDossierModule.certificationDisponible
            module.CertificationObligatoire__c = savedDossierModule.certificationObligatoire
        }
    }
    if (dossierDTO.Modules && dossierDTO.Modules.length > 0) {
        const module = dossierDTO.Modules[0] as DossierAFModuleAgoraDTO
        module.FormationCertifianteAgora__c ??= FORMATION_CERTIFIANTE_NON
        module.ObjectifGeneralOfAgora__c ??= OBJECTIF_GENERAL_OF_PERFECTIONNEMENT
        module.ObjectifGeneralFormationAgora__c ??= OBJECTIF_GENERAL_FORMATION_COMPETENCES            
    }
    
    return dossierDTO
}

const api: DossierAPI<DossierACN> = {
    create: createDossierACN,
    createModule: () => createDossierACNModule(),
    createSalarie: createDossierSalarie,
    load: async (dossierId: string, storedState?: DossierACN | null) => {
        let dossierDTO = await loadDossierAF(dossierId)
        dossierDTO = await postLoadDossierACN(dossierDTO, storedState)
        return mapDTOToDossierAF(MetadataACN, dossierDTO)
    },
    loadWithPieces: async (dossierId: string, storedState?: DossierACN | null) => {
        let dossierDTO = await loadDossierAF(dossierId)
        dossierDTO = await postLoadDossierACN(dossierDTO, storedState)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        return mapDTOToDossierAF(MetadataACN, dossierDTO)
    },
    save: saveDossierACN,
    send: async (dossier: DossierACN) => {
        const dossierDTO = mapDossierAFToDTO(MetadataACN, dossier)
        const sentDossierDTO = await sendDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataACN, sentDossierDTO)
    },
    postSend: async (dossier: DossierACN) => {
        await postSendDossier(MetadataACN, dossier)
    },
}

const displayCoutPedagogique = (props: ElementContext<DossierACNModule, DossierACN>, total = true) => { 
    const m = props.parent
    const coutPedagogique = m?.coutPedagogique?.montant ?? 0
    const coutStage = m?.coutStage ?? 0
    
    let coutSupportCours = m?.coutSupportCoursRevise ?? 0
    let coutCertification = m?.coutCertificationRevise ?? 0
    if (coutSupportCours > 0) {
        if (!m?.supportCours)
            coutSupportCours = 0
    }
    if (coutCertification > 0) {
        if (!m?.certification)
            coutCertification = 0
    }
    const typeFormation = m?.typeFormation

    const coutParStagiaire = coutSupportCours + coutCertification
    const totalAmount = total ? (', soit ' + formatCurrency(coutPedagogique, ' HT')) : ''
    if (typeFormation === TYPE_FORMATION_INTRA) {
        if (coutParStagiaire === 0)
            return formatCurrency(coutStage, ' HT')
        return `${ formatCurrency(coutStage, ' HT') } + (${ formatCurrency(coutParStagiaire, ' HT / stagiaire') })${ totalAmount }`
    }

    if (coutParStagiaire === 0)
        return formatCurrency(coutStage, ' HT / stagiaire')
    return `(${ formatCurrency(coutStage, ' HT / stagiaire') }) + (${ formatCurrency(coutParStagiaire, ' HT / stagiaire') })${ totalAmount }`
}

const contextesSpecifiquesACN = (refs?: References | null) => refs?.REF_CONTEXTE_SPECIFIQUE?.filter(
    r => r.IsActive__c 
        && (entreprise()?.NumberOfEmployees ?? 0) >= 50 
        && config()?.ContexteSpecifiqueAcn?.find(c => c.ContexteSpecifiqueId === Number(r.Id))
            ?.BranchesAutorisees?.includes(entreprise()?.Branche__c ?? '')
) ?? []

const ACNModuleMetadata: ObjectMetadata<DossierACNModule> = {
    formationInterne: override(moduleFields.formationInterne, { section: SECTION_MODULE_OF, input: undefined, recap: undefined, export: undefined }),
    cfa: override(moduleFields.cfa, { props: { disabled: true, da: true }, section: SECTION_MODULE_OF }),
    subrogation: override(moduleFields.subrogation, { section: SECTION_MODULE_OF, input: undefined, recap: undefined, export: undefined }),
    reference: TextFieldMetadata({
        props: {
            label: 'Référence',
            disabled: true
        },
        mapping: simpleMapping('Reference__c'),
    }).required(),
    intitulePrecis: override(moduleFields.intitulePrecis, { props: { disabled: true, tooltip: undefined } }),
    niveauVise: moduleFields.niveauVise,
    dureeTotale: override(moduleFields.dureeTotale, { props: { disabled: true } }),
    dateDebutFormation: override(moduleFields.dateDebutFormation, { props: {
        disabled: (context: ElementContext<DossierACNModule>) => (
            context.parent != null && context.parent.typeFormation === TYPE_FORMATION_INTER && context.parent.modalite !== MODALITE_STAGE_EN_LIGNE
        )}
    }),
    dateFinFormation: override(moduleFields.dateFinFormation, { props: {
        disabled: (context: ElementContext<DossierACNModule>) => (
            context.parent != null && context.parent.typeFormation === TYPE_FORMATION_INTER && context.parent.modalite !== MODALITE_STAGE_EN_LIGNE
        )}
    }),
    coutPedagogique: override(moduleFields.coutPedagogique, { 
        props: {
            label: (context: ElementContext<DossierACNModule, DossierACN>) => { 
                const numStagiaires = context.root.salaries?.filter((s: any) => s?.IdHeroku !== undefined).length ?? 0
                return 'Coût pédagogique total' + (numStagiaires > 0 ? ' pour ' + numStagiaires + ' stagiaire' + (numStagiaires > 1 ? 's' : '') : '')
            },
            exportLabel: 'Coût pédagogique total',
            suffix: undefined,
        },
        yop: Ignored,
        input: (props: any) => <PseudoTextField { ...props } value={ displayCoutPedagogique(props, false) } />,
        recap: (props: any) => <RecapTextField { ...props } value={ displayCoutPedagogique(props) } />,
        export: (props: any) => <ExportTextField { ...props } value={ displayCoutPedagogique(props) } />,
        mappedValue: (context: ElementContext<DossierACNModule, DossierACN>) => { 
            const numStagiaires = context.root.salaries?.filter((s: any) => s?.IdHeroku !== undefined).length ?? 0
            const m = context.parent
            
            let coutSupportCours = m?.coutSupportCoursRevise ?? 0
            let coutCertification = m?.coutCertificationRevise ?? 0
            if (coutSupportCours > 0) {
                if (!m?.supportCours)
                    coutSupportCours = 0
            }
            if (coutCertification > 0) {
                if (!m?.certification)
                    coutCertification = 0
            }
            const coutTotal = m?.typeFormation === TYPE_FORMATION_INTRA
                ? formatAmountNumber(((m?.coutStage ?? 0) + numStagiaires * ((m?.supportCours ? coutSupportCours ?? 0 : 0) + (m?.certification ? coutCertification ?? 0 : 0))))
                : formatAmountNumber((numStagiaires * ((m?.coutStage ?? 0) + (m?.supportCours ? coutSupportCours ?? 0 : 0) + (m?.certification ? coutCertification ?? 0 : 0))))
            return {
                IdHeroku: context.value?.IdHeroku ?? null,
                typeCout: TYPE_COUT_PEDAGOGIQUE,
                montant: coutTotal,
            }
        },

    }),
    supportCours: BooleanFieldMetadata({
        props: {
            label: (context: ElementContext<DossierACNModule, DossierACN>) =>
                (context.parent?.coutSupportCoursRevise ?? 0) > 0 ?
                `Support de cours (${formatAmount(context.parent?.coutSupportCoursRevise)}€ HT / stagiaire)` :
                'Support de cours',
            exportLabel: 'Support de cours',
            disabled: true,
        },
        mapping: simpleMapping('SupportCours__c'),
    }).mutate(yop => yop.when((c: ValidationContext<boolean | null, DossierACNModule>) => (c.parent?.coutSupportCours ?? 0) > 0 ? RequiredBoolean : null)),
    certification: BooleanFieldMetadata({
        props: {
            label: (context: ElementContext<DossierACNModule, DossierACN>) =>
                (context.parent?.coutCertificationRevise ?? 0) > 0 ?
                `Certification (${formatAmount(context.parent?.coutCertificationRevise)}€ HT / stagiaire)` :
                'Certification',
            exportLabel: 'Certification',
            disabled: (ctx: ElementContext<DossierACNModule>) => ctx.parent?.certificationObligatoire || (ctx.parent?.coutCertificationRevise ?? 0) === 0,
        },
        mapping: simpleMapping('Certification__c'),
    }).mutate(yop => yop.when((c: ValidationContext<boolean | null, DossierACNModule>) =>
        c.parent?.certificationObligatoire || (c.parent?.coutCertificationRevise ?? 0) > 0 ? RequiredBoolean : null)
    ),
    typeFormation: override(moduleFields.typeFormation, { props: { disabled: true } }),
    contexteSpecifique: SelectRefFieldMetadata({
        props: {
            label: 'Contexte spécifique',
            tooltip: TOOLTIPS.formation.contexteSpecifique,
            options: refs => contextesSpecifiquesACN(refs),
            sorted: true,
        },
        mapping: simpleMapping('ContexteSpecifique__c'),
        visibleIf: (context: ElementContext<DossierACNModule, DossierACN>) => context.parent?.formationInterne !== null && (contextesSpecifiquesACN(references())?.length ?? 0) > 0,
    }),
    commentaire: moduleFields.commentaire,
    referenceSource: IgnoredFieldMetadata(simpleMapping('ReferenceSource__c', 'dossier')),
    campusSession: IgnoredFieldMetadata(simpleMapping('CampusSession__c', 'dossier')),
    certificationDisponible: IgnoredFieldMetadata(simpleMapping('CertificationDisponible__c')),
    certificationObligatoire: IgnoredFieldMetadata(simpleMapping('CertificationObligatoire__c')),
    // Champs additionnels stage non editables
    coutStage: IgnoredFieldMetadata(simpleMapping('CoutStage__c')),
    coutSupportCours: IgnoredFieldMetadata(simpleMapping('CoutSupportCours__c')),
    coutCertification: IgnoredFieldMetadata(simpleMapping('CoutCertification__c')),
    coutSupportCoursRevise: IgnoredFieldMetadata(simpleMapping('CoutSupportDeCoursRevise__c')),
    coutCertificationRevise: IgnoredFieldMetadata(simpleMapping('CoutCertificationRevise__c')),
    minStagiaires: IgnoredFieldMetadata(simpleMapping('MinStagiaires__c')),
    maxStagiaires: IgnoredFieldMetadata(simpleMapping('MaxStagiaires__c')),
    dateDebutValidite: IgnoredDateFieldMetadata(dateMapping('DateDebutValidite__c')),
    dateFinValidite: IgnoredDateFieldMetadata(dateMapping('DateFinValidite__c')),
    modalite: IgnoredFieldMetadata(simpleMapping('Modalite__c')),
    formationCertifianteAgora: override(moduleAgoraFields.formationCertifianteAgora, { props: { disabled: true } }),
    categorieActionAgora: override(moduleAgoraFields.categorieActionAgora, { props: { disabled: true } }),
    objectifAgora: moduleAgoraFields.objectifAgora,
    fne: IgnoredFieldMetadata(simpleMapping('Fne'))
}

const ACNSalarieMetadata: ObjectMetadata<DossierACNSalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: salarieFields.prenom,
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    handicap: salarieFields.handicap,
    statut: salarieFields.statut,
    contrat: salarieFields.contrat,
    niveauFormation: salarieFields.niveauFormation,
    dateEntree: salarieFields.dateEntree,
    categorieAction: salarieFields.categorieAction,
    coefficient: salarieFields.coefficient,
    autreMetier: salarieFields.autreMetier,
    situationHandicap: IgnoredFieldMetadata(),
    heuresHTT: IgnoredFieldMetadata(),
    heuresAfest: IgnoredFieldMetadata(),
}

type DossierACNLight = Omit<DossierACN, 'pieces'>
const ACNMetadataLight: ObjectMetadata<DossierACNLight> = {
    employeur: ObjectFieldMetadata(employeurMetadata),
    modules: ArrayFieldMetadata(ACNModuleMetadata),
    salaries: ArrayFieldMetadata(ACNSalarieMetadata),
    signature: ObjectFieldMetadata(signatureMetadata),
}
const ACNMetadata = ACNMetadataLight as ObjectMetadata<DossierACN>

export const MetadataACN = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_ACN,
    title: "Dépôt dossier Action collective campusAtlas",
    pathname: '/nouveau-dossier-acn',
    steps: [
        {
            title: 'campusAtlas',
            pathname: '/nouveau-dossier-acn',
            sections: [],
            formContent: DossierACNCampusElements,
            formButtons: DossierACNCampusButtons,
            stepButton: DossierACNCampusStep, 
        }, { 
            title: 'Employeur',
            pathname: '/nouveau-dossier-acn-campus',
            sections: [],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            formContent: DossierACNEmployeurElements,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Formation',
            sections: [SECTION_MODULE_AGORA],
            formContent: DossierModulesElements,
            recap: RecapModules,
            initializer: dossierModulesInitializer,
            yopPath: 'modules',
        }, {
            title: 'Salariés',
            sections: [],
            preFormContent: DossierSalariesTable,
            editForm: DossierSalariesEditForm,
            recap: RecapSalaries,
            initializer: dossierMultiSalariesInitializer,
            yopPath: 'salaries',
            options: {
                noFormHeading: true,
                minElements: (dossier: DossierACN) => {
                    const module = dossier.modules?.[0] ?? null
                    if (module?.typeFormation === TYPE_FORMATION_INTRA && (module?.minStagiaires ?? 0) > 1) {
                        return { 
                            value: dossier.modules[0].minStagiaires!, 
                            message: `Vous devez ajouter au moins ${dossier.modules[0].minStagiaires} salariés pour ce stage`,
                        }
                    }
                    return { value: 1, message: 'Vous devez ajouter au moins un salarié' }
                },
                maxElements: (dossier: DossierACN) => {
                    const module = dossier.modules?.[0] ?? null
                    if (module?.typeFormation === TYPE_FORMATION_INTRA && (module?.maxStagiaires ?? 0) > 1) {
                        return { 
                            value: dossier.modules[0].maxStagiaires!, 
                            message: `Vous devez ajouter au plus ${dossier.modules[0].maxStagiaires} salariés pour ce stage`,
                        }
                    }
                    return null
                },
                noSaving: true,
                forceValidate: (dossier: DossierACN) => {
                    const module = dossier.modules?.[0] ?? null
                    if (module?.typeFormation === TYPE_FORMATION_INTRA && (module?.minStagiaires ?? 0) > 1)
                        return dossier.salaries?.length >= dossier.modules[0].minStagiaires!
                    return dossier.salaries?.length >= 1
                },
            },
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: props => <DossierPiecesElements { ...props } piecesObligatoiresMessage={ <ACNPiecesObligatoiresMessage /> }/>,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: ACNMetadata,
    exportProps: [{
        exportDossier: async (metadata, dossier) => await createCerfaDpc(exportDossier(metadata, dossier)),
        documentType: DOCTYPE_DPC,
        documentName: DOCNAME_DPC,
    }, {
        afterSubmit: true,
        label: 'Télécharger la demande de subvention FNE',
        exportDossier: async (metadata, dossier) => {
            const data = await exportAttestationFNE(metadata, dossier)
            return await createCerfaFne(data)
        },
        documentType: DOCTYPE_SUBVENTION_FNE,
        documentName: `${ DOCTYPE_SUBVENTION_FNE }.pdf`,
        ignoredIf: (dossier) => dossier?.modules?.[0]?.fne !== true,
    }],
})

function ACNPiecesObligatoiresMessage() {
    return <ModelePiece modeles={ [{ 
        label: "Attestation sur l'honneur" ,
        href: "https://www.opco-atlas.fr/sites/default/files/medias/files/2023/11/Attestation%20intercontrat%20Airbus.pdf",
        when: (dossier?: DossierACN) => dossier?.modules?.some(module => module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS || module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS_FNE || module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS_CPF) ?? false
    }] } />
}
