import { Form, useForm } from '@dsid-opcoatlas/reform'
import { Document, pdf } from '@react-pdf/renderer'
import { postAnnulerDossier } from 'api/dossierAPI'
import { dispatchAppEvent } from 'app/events'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasFlex, AtlasInfo, MyForm, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import { buildPdfAnnuler } from 'components/annuler/generateCerfa'
import { parseDate } from 'components/format/Format'
import SelectField from 'components/reform/inputs/SelectField'
import { optionalObject, RequiredString } from 'components/yop/constraints'
import { addDays, isBefore, startOfDay } from 'date-fns'
import log from 'loglevel'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { detailDossierContratRefreshEvent, detailDossierDocumentsRefreshEvent, DossierActionProps } from './DetailDossierContrat'


export default function DossierAnnulerEntreprise(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    const [showAnnulerDossier, setShowAnnulerDossier] = useState(false)
    const [actionPending, setActionPending] = useState(false)
    const { profil } = useSelector((state: RootState) => state.profilState)
    const authName = `${profil?.Contact?.Email ?? ''}`

    const initialValues = {
        motif: '',
    }

    type Annulation = typeof initialValues

    const confirmAnnuler = async (motif: string) => {
        setActionPending(true)
        try {
            const instance = pdf(<Document />);
            instance.updateContainer(buildPdfAnnuler(dossier, authName, motif));
            const blob = await instance.toBlob();

            await postAnnulerDossier(dossier?.NumeroDossier__c, 'DEMANDE_ANNULATION', motif, blob, 'demande-annulation-' + dossier.NumeroDossier__c + '.pdf')
            log.debug(`Upload DEMANDE_ANNULATION  ok`)
        }
        catch (e) {
            log.error('Erreur annulation dossier ' + dossier.NumeroDossier__c, e)
            ToastService.showToast({
                label: 'Annuler dossier',
                type: 'error',
                content: 'Une erreur est survenue. Veuillez réessayer ultérieurement',
            })
        }

        // Attend 5 secondes et rafraîchit le statut et la liste des documents (récupération du récépissé)
        setTimeout(() => {
            ToastService.showToast({
                label: 'Annuler dossier',
                type: 'success',
                content: "Votre demande d'annulation a bien été prise en compte. Elle sera traitée dans les meilleurs délais",
            })
            dispatchAppEvent(detailDossierContratRefreshEvent)
            dispatchAppEvent(detailDossierDocumentsRefreshEvent)
            setActionPending(false)
            setShowAnnulerDossier(false)
            form.reset()
        }, 5000)
    }

    const retour = () => {
        setShowAnnulerDossier(false)
        form.reset()
    }

    const form = useForm({
        initialValues,
        validationSchema: optionalObject<Annulation>({
            motif: RequiredString,
        }),
        dispatchEvent: false,
        onSubmit(context) {
            confirmAnnuler(context.values!.motif)
        },
    })

    const isConditionConformite = !(
        dossier.IndicateurConformite__c === 'Vert' &&
        dossier.DateReception__c != null &&
        isBefore(Date.now(), addDays(startOfDay(parseDate(dossier?.DateReception__c)!), 1))
    )

    return <>
        {isConditionConformite &&
            <AtlasButton onClick={() => setShowAnnulerDossier(true)} level={2}>
                Annuler le dossier
            </AtlasButton>
        }

        {showAnnulerDossier &&
            <MyModal show={showAnnulerDossier}
                label="Demande d'annulation"
                isPending={actionPending}>

                <Form context={form} noValidate>
                    <MyForm>
                        <AtlasFlex column gap="l">
                            <SelectField name="motif" label="Motif de la demande"
                                options={[
                                    {
                                        value: '',
                                        label: 'Choisir un motif',
                                    }, {
                                        value: "Le jeune n'a pas obtenu le titre pré requis à la conclusion de ce contrat",
                                        label: "Le jeune n'a pas obtenu le titre pré requis à la conclusion de ce contrat",
                                    }, {
                                        value: "Le jeune s'est désisté ou ne s'est pas présenté",
                                        label: "Le jeune s'est désisté ou ne s'est pas présenté",
                                    }, {
                                        value: "L'entreprise a renoncé au projet de recrutement",
                                        label: "L'entreprise a renoncé au projet de recrutement",
                                    }, {
                                        value: "Un évènement majeur empêche l'exécution du contrat (décès, longue maladie, maternité, liquidation, ....)",
                                        label: "Un évènement majeur empêche l'exécution du contrat (décès, longue maladie, maternité, liquidation, ....)",
                                    },
                                    {
                                        value: "Un autre dossier est déjà déposé pour le même contrat",
                                        label: "Un autre dossier est déjà déposé pour le même contrat",
                                    },
                                ]} />
                        </AtlasFlex>

                        <AtlasInfo type="warning" title="Attention">
                            <p>Le contrat d'apprentissage peut être annulé s'il n'a pas commencé. Cette fonction est à utiliser uniquement si le contrat ne s'exécutera finalement pas.</p>

                            <p>Si vous souhaitez modifier un élément sur le dossier saisi, nous vous invitons à cliquer sur "Corriger le contrat".</p>

                            <p>
                                Si le contrat a commencé, il peut être rompu mais pas annulé, voir la FAQ du site atlas : <a
                                    target="_blank" href="https://www.opco-atlas.fr/faq/contrat-dapprentissage.html#question-reponse-faq-131"
                                >Quelles sont les modalités de rupture d'un contrat d'apprentissage ?</a>
                            </p>

                            <p>En cas de doute sur la démarche, contacter les équipes d'Atlas.</p>
                        </AtlasInfo>

                        <p>Confirmez-vous l'annulation de votre dossier N°{dossier.NumeroDossier__c} ?</p>

                        <MyForm.Actions>
                            <AtlasButton type="submit" destructive
                                spinner={{ spinning: form.submitting }}
                                disabled={initialValues && !form.isDirty()}
                            >Confirmer</AtlasButton>
                            <AtlasButton level={2} onClick={retour}>
                                Retour
                            </AtlasButton>
                        </MyForm.Actions>
                    </MyForm>
                </Form>
            </MyModal>
        }
    </>
}

